
const getEventGroupName = (eventGroupId) => {
  let value = "";
  switch (eventGroupId) {
    case 1:
      value = "Genel";
      break;
    case 2:
      value = "Beyler";
      break;
    case 3:
      value = "Hanımlar";
      break;
    default:
      break;
  }

  return value;
}
const getGenderName = (genderId) => {
  let value = "";
  switch (genderId) {
    case 2:
      value = "Erkek";
      break;
    case 3:
      value = "Hanım";
      break;
    default:
      break;
  }

  return value;
}

const getEventTypeName = (eventTypeId) => {
  let value = "";
  switch (eventTypeId) {
    case 1:
      value = "Etkinlik";
      break;
    case 2:
      value = "Yarışma";
      break;
    default:
      break;
  }

  return value;
}

export {
  getEventGroupName,
  getEventTypeName,
  getGenderName
}