import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useEffect, useState } from 'react'
import { ServiceHelper } from '../utils/service-helper';
import { getSession } from '../utils/auth-helper';

function OrganizationSelect({ onChange, selectedOrganization }) {
  const [organizationList, setOrganizationList] = useState();

  useEffect(() => {
    getOrganizations();
  }, []);

  async function getOrganizations() {
    const { id } = await getSession();
    const response = await ServiceHelper.get(`/organization/getAll/${id}`);
    setOrganizationList(response.data.result);
  }

  const handleOrganizationChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Stack display={'flex'} direction={'row'}>
      <FormControl fullWidth>
        <InputLabel id="organization-label">{'Organizasyon'}</InputLabel>
        <Select
          labelId="organization-label"
          id="organization-id"
          value={selectedOrganization == null ? '' : selectedOrganization}
          defaultValue={''}
          label={'Organizasyon'}
          onChange={handleOrganizationChange}
        >
          {
            organizationList?.map((item, key) => {
              return <MenuItem key={`${item.id}_${key}`} value={item.id}>{item.name}</MenuItem>;
            })
          }
        </Select>
      </FormControl>
    </Stack>
  )
}

export default OrganizationSelect