import React, { useEffect, useState } from 'react'
import BasePage from '../core/base-page'
import { Container } from '@mui/material'
import TopNavigation from '../../components/top-navigation'
import { Button, Card, Divider, Space, Table } from 'antd'
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom'
import { ServiceHelper } from '../../utils/service-helper'
import { WhatsAppOutlined } from '@ant-design/icons';
import { getGuestSession } from '../../utils/auth-helper'

function Friends() {

  const [guestList, setGuestList] = useState([]);
  const [gusetInfo, setGuestInfo] = useState();

  useEffect(() => {
    getGuestFriend();
    getFriends();
  }, []);

  const getGuestFriend = async () => {
    const gusetInfo = await getGuestInfo();
    setGuestInfo(gusetInfo);
  }

  const getFriends = async () => {
    const { organizationId, id } = await getGuestSession();
    const response = await ServiceHelper.get(`/guest/getFriendList/${organizationId}/${id}`);
    console.log(response);
    if (response.success) {
      // const { guest, events, eventAttendance } = response.data.result;
      setGuestList(response.data.result)
      // dispatch(statusSlice.actions.setStatus({ ...response, status: true }))      

    } else {

    }
  }

  async function getGuestInfo() {
    const { guidId } = await getGuestSession();
    const response = await ServiceHelper.get(`/guest/getByGuid/${guidId}`);
    const { firstName, lastName, provionce } = response.data.result;
    return { firstName, lastName, provionce };
  }


  const columns = [
    {
      title: 'Adı',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 100
    },
    {
      title: 'Soyadı',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 100
    },
    {
      title: 'Telefon',
      key: 'phoneNumber',
      width: 170,
      // dataIndex: 'phoneNumber',
      render: (_, record) => (
        <Space size="middle" >
          <Button icon={<WhatsAppOutlined />} onClick={() => {

            let url = `whatsapp://send?text=${encodeURIComponent("Selamunaleyküm,\nBen ")}${gusetInfo.firstName} ${gusetInfo.lastName}${encodeURIComponent(". Server Aile Buluşma Programına ")}${gusetInfo.provionce} ${encodeURIComponent("ilinden katılıyorum. Tanıştığıma memnun oldum. Tekrar görüşmek dileğiyle")}&phone=90${record.phoneNumber}`

            window.open(url);
          }}>{record.phoneNumber}
          </Button>
        </Space>
      )
    },
    {
      title: 'İl',
      dataIndex: 'provionce',
      key: 'provionce',
      width: 100,
    }

  ]
  return (
    <BasePage maxWidth={'md'}>
      <Container maxWidth="md" sx={{}} style={{ padding: 8, justifyContent: 'center' }}>
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <TopNavigation title={"Tanıştıklarım"} />
          <Table scroll={{ x: 1 }} dataSource={guestList} columns={columns} style={{ padding: -24 }} />
        </Card>
      </Container>
    </BasePage>
  )
}

export default Friends