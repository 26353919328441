import { Grid, Stack } from '@mui/material';
import Container from '@mui/material/Container';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { guestSlice } from '../app/reducers/guest';
import { statusSlice } from '../app/reducers/status';
import TopNavigation from '../components/top-navigation';
import { getGuestSession, getGuestSessionStatus, getSessionStatus } from '../utils/auth-helper';
import { getQRCode } from '../utils/qr-code-helper';
import BasePage from './core/base-page';
import { Button, Card } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

function Home(props) {
  const [imageList, setImageList] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [qrValue, setQrValue] = useState("");
  const [qr, setQr] = useState("");
  const generateQRCode = useCallback((guidId) => {
    const qrValue1 = getQRCode("http://localhost:3000/api/guest/getByGuid/" + guidId);
    if (!qrValue1) return;
    setQr(qrValue1);
  }, [qrValue, setQr]);

  useEffect(() => {
    // console.log('Effect');
    let response = getAll()
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      // const { from } = location.state || { from: { pathname: "/" } };
      // dispatch(userSlice.actions.login(response.data));
      // Navigate(from.pathname);
    }

    async function getAll() {
      // const response = await ServiceHelper.get('/category/getAll');
      // dispatch(statusSlice.actions.setStatus([]))
      // console.log(response);
      // setImageList(response.data.result);
    }

    getGuestInfo();

  }, []);

  async function getGuestInfo() {
    const status = await getSessionStatus();
    // console.log('Guest Session: ', result);
    // generateQRCode(result.guidId);

    status ? navigate('/admin/dashboard') : navigate('/guest/dashboard');
    // const userInfo = await ServiceHelper.get(`/users/get/${id}`);
    // const { firstName, lastName, email } = userInfo.data.result;
    // dispatch(userSlice.actions.setUserInfo({ data: { id, firstName, lastName, email } }))
  }

  const logout = async () => {
    dispatch(guestSlice.actions.logout());
    await localStorage.clear();
  }

  return (
    <BasePage maxWidth={'sm'}>
      <Container maxWidth="sm" sx={{}} style={{ padding: 8 }}>
        {/* <Grid container spacing={2}> */}
        <Card>
          <TopNavigation showNavigation={false} title={'Hoşgeldiniz'} />
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 48, paddingBottom: 48 }}>
            <Button type="primary" shape="round" icon={<LoginOutlined />} size={"large"} onClick={() => { navigate('/guest/login') }}>Misafir Girişi</Button>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="link" shape="round" icon={<LoginOutlined />} size={"small"} onClick={() => { navigate('/admin/login') }}>Yönetici Girişi</Button>
          </div>
        </Card>

        {/* <Grid container spacing={2}>
            <Grid item sm={6} xs={6}>
              <img src={qr} alt='' />
            </Grid>
            <Grid item sm={6} xs={6}>
              <Typography variant='h6'>Mustafa Yonca</Typography>
              <Button onClick={logout}>Çıkış Yap</Button>
            </Grid>
          </Grid> */}
        {/* <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Program Akışı</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Etkinlikler</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Bilgilendirme</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Tanıştıklarım</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Hesap Bilgilerim</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion> */}
        {/* </Grid> */}
      </Container>
    </BasePage>
  )
}

export default Home


