import React, { useEffect, useState } from 'react'
import BasePage from '../core/base-page'
import { Container, Stack, Typography as MuiTypography } from '@mui/material'
import TopNavigation from '../../components/top-navigation'
import { Button, Card, Divider, Space, Table, Typography } from 'antd'

import { InfoCircleOutlined, UserAddOutlined, UserDeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { getGuestSession } from '../../utils/auth-helper'
import { ServiceHelper } from '../../utils/service-helper'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { useTheme } from '@emotion/react'
import { statusSlice } from '../../app/reducers/status'
import { Status } from '../../utils/status-enum'
import { useDispatch } from 'react-redux'

function Events() {
  const dispatch = useDispatch();
  const [eventList, setEventList] = useState([]);
  const [guestEventList, setGuestEventList] = useState([]);
  const [loadings, setLoadings] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    getEventList();
  }, []);

  const getEventList = async () => {
    const { organizationId, id, gender } = await getGuestSession();
    const response = await ServiceHelper.get(`/event/getAllEvents/${organizationId}/${1}/${gender}`);
    console.log(response);
    if (response.success) {
      // const { guest, events, eventAttendance } = response.data.result;
      const guestEvent = await getGuestAttendance(id);
      setGuestEventList(guestEvent);
      setEventList(response.data.result);

      // dispatch(statusSlice.actions.setStatus({ ...response, status: true }))      

    } else {

    }
  }

  const getGuestAttendance = async (guestId) => {
    const response = await ServiceHelper.get(`/eventattendancelog/getGuestAttendance/${guestId}`);
    if (response.success) {
      return response.data.result;
    } else {
      return [];
    }
  }

  const onClickAttend = async (eventId, status) => {
    setLoadings(true);
    const { id } = getGuestSession();
    let response = await ServiceHelper.post('/event/track', { eventId, guestId: id, status: status });
    if (response.success) {
      console.log(response.status);
      if (response.status === Status.SUCCESS) {
        dispatch(statusSlice.actions.setStatus({ message: response.message, type: 'success', status: true }))
      } else if (response.status === Status.WARNING) {
        dispatch(statusSlice.actions.setStatus({ message: response.message, type: Status.WARNING, status: true }))
      }
    } 
    else {
      
    }
    await getEventList();
    setLoadings(false);
  }

  const columns = [

    {
      title: 'Etkinlik Adı',
      key: 'name',
      width: 300,
      render: (_, record) => (
        <Stack direction={'column'}>
          <Space>
            <Button type='link' icon={<InfoCircleOutlined />} onClick={() => {
              navigate('/guest/event-detail', { state: { event: record } });
            }}>
            </Button>
            <MuiTypography>{record.name}</MuiTypography>
          </Space>
          <Stack direction={'row'} display={'flex'} justifyContent={'space-around'}>
              <MuiTypography variant='caption' >{`Kontenjan: ${record.userCount} / ${record.quota}`}</MuiTypography>
              <MuiTypography variant='caption'>{dayjs(record.startDate).locale('tr').format('dddd - HH:mm')}</MuiTypography>
          </Stack>
        </Stack>
      )
    },

    // {
    //   title: 'Kontenjan',
    //   key: 'kontenjan',
    //   width: 150,
    //   render: (_, record) => {
    //     return <Space>
    //       {`${record.userCount} / ${record.quota}`}
    //     </Space>
    //   }
    // },
    // {
    //   title: 'Tarih',
    //   key: 'date',
    //   width: 160,
    //   render: (_, record) => (
    //     <Space size="middle" >
    //       <Typography>{dayjs(record.startDate).locale('tr').format('dddd - HH:mm')}</Typography>
    //     </Space>
    //   )
    // },
    {
      title: 'Katılım Durumu',
      key: 'attandenceDetails',
      width: 110,
      fixed: 'right',
      render: (_, record) => {
        console.log(record.isOpen);
        if (guestEventList?.find(p => p.eventId == record.id) && parseInt(record.userCount) >= record.quota) {
          return (<Space size="middle" >
            <Button icon={<UserDeleteOutlined />} type={'primary'} loading={loadings}
              style={{ backgroundColor: theme.palette.error.main }}
              onClick={() => {
                // navigate('/guest/event-detail', { state: { eventId:  record.id} });
                onClickAttend(record.id, 0);
              }}>{`Ayrıl`}
            </Button>
          </Space>)
        }
        else if (parseInt(record.userCount) >= record.quota) {
          return (
            <Space size="middle" >
              <Button icon={<UserDeleteOutlined />} type={'dashed'} disabled danger onClick={() => {
                // navigate('/guest/event-detail', { state: { eventId:  record.id} });
              }}>{`Doldu`}
              </Button>
            </Space>
          )
        } else if (record.isOpen === false) {
          return (
            <Space size="middle" >
              <Button icon={<WarningOutlined />} type={'dashed'} disabled danger onClick={() => {
              }}>{`Pasif`}
              </Button>
            </Space>
          )
        } else if (guestEventList?.find(p => p.eventId == record.id)) {
          return (<Space size="middle" >
            <Button icon={<UserDeleteOutlined />} type={'primary'} loading={loadings}
              style={{ backgroundColor: theme.palette.error.main }}
              onClick={() => {
                // navigate('/guest/event-detail', { state: { eventId:  record.id} });
                onClickAttend(record.id, 0);
              }}>{`Ayrıl`}
            </Button>
          </Space>)
        } else {
          return (
            <Space size="middle" >
              <Button icon={<UserAddOutlined />} type={'primary'} loading={loadings}
                style={{ backgroundColor: theme.palette.success.main }}
                onClick={() => {
                  // navigate('/guest/event-detail', { state: { eventId:  record.id} });
                  onClickAttend(record.id, 1);
                }}>{`Katıl`}
              </Button>
            </Space>
          )
        }

      }
    },

  ]

  return (
    <BasePage maxWidth={'md'}>
      <Container maxWidth="md" sx={{}} style={{ padding: 8, justifyContent: 'center' }}>
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <TopNavigation title={"Etkinlikler"} />
          <Table scroll={{ x: 100 }} pagination={false} dataSource={eventList} columns={columns} />
        </Card>
      </Container>
    </BasePage>
  )
}

export default Events