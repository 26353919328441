import React, { useEffect, useState } from 'react'
import BasePage from '../core/base-page'
import { Box, Button, CardActionArea, CardContent, CardMedia, Container, Grid, Card as MuiCard, Typography as MuiTypography, Typography } from '@mui/material'
import GuestTypography from '../../components/guest-typography'
import { Avatar, Card, Divider, List, QRCode, Button as AntButton, Space } from 'antd';
import TimelineIcon from '@mui/icons-material/Timeline';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HelpIcon from '@mui/icons-material/Announcement';
import AddPersonIcon from '@mui/icons-material/PersonAdd';
import { getGuestSession } from '../../utils/auth-helper';
import { ServiceHelper } from '../../utils/service-helper';
import { useNavigate } from 'react-router-dom';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LogoYatay from '../../assets/images/logo-yatay.png'
import { useTheme } from '@emotion/react';

function GuestDashboard() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [guestInfo, setGuestInfo] = useState({
    firstName: '',
    lastName: '',
    provionce: '',
    phoneNumber: ''
  })

  useEffect(() => {
    getGuestInfo();
  }, []);

  async function getGuestInfo() {
    const { guidId } = await getGuestSession();
    const response = await ServiceHelper.get(`/guest/getByGuid/${guidId}`);
    setGuestInfo(response.data.result);
    // console.log('guidId', response);
  }
  const {
    firstName,
    lastName,
    provionce,
    phoneNumber, guidId, organizationId } = guestInfo;
  const sxIcon = { width: 36, height: 36 };
  const data = [
    {
      title: 'Program Akışı',
      icon: <TimelineIcon sx={Object.assign({}, sxIcon, { color: '#4299c9' })} />,
      link: '/guest/timeline'
    },
    {
      title: 'Etkinlikler',
      icon: <EventNoteIcon sx={Object.assign({}, sxIcon, { color: '#c9c642' })} />,
      link: '/guest/events'
    },
    {
      title: 'Tanıştıklarım',
      icon: <SentimentSatisfiedAltIcon sx={Object.assign({}, sxIcon, { color: '#42c971' })} />,
      link: '/guest/friends'
    },
    {
      title: 'Duyurular',
      icon: <HelpIcon sx={Object.assign({}, sxIcon, { color: '#c94242' })} />,
      link: '/guest/announcement'
    },
  ];

  console.log('Theme: ', theme);
  return (
    <BasePage maxWidth={'md'}>
      <Container maxWidth="md" sx={{}} style={{ padding: 8, justifyContent: 'center' }}>
        <MuiCard>
          <CardContent>

            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <Space style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <MuiTypography variant='body1'>{firstName} {lastName}</MuiTypography>
                  <MuiTypography color={'GrayText'} variant='body2'>{provionce}</MuiTypography>
                </Space>
              </Grid>
              <Grid item sm={12} xs={12} >
                <Space style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <QRCode style={{ marginTop: 16 }} errorLevel="H" value={ServiceHelper.getUrl(`guest/profile/${organizationId}/${guidId}`)} size={196} />
                </Space>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Space style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <AntButton 
                  style={{ 
                  color: theme.palette.primary.main,
                //   width: '196px', height: '70px', fontSize: '1.5em' ,
                  display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center'
                }} 
                type="link"  shape="rounded" icon={<QrCodeScannerIcon />} onClick={() => { navigate('/guest/scan') }}>
                    QR Kod Tara
                  </AntButton>
                </Space>

              </Grid>

              {/* <Divider plain style={{ marginLeft: 8, marginBottom: 0 }}></Divider> */}
              {/* <Grid item sm={12} xs={12}>




                <List
                  size='large'
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={item.icon}
                        title={<a href={item.link}>{item.title}</a>}

                      // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                      />
                    </List.Item>
                  )}
                />
              </Grid> */}
              {
                data?.map((p, key) => (
                  <Grid key={`menu_${key}`} item sm={6} xs={6} sx={{ display: 'grid' }}>
                    <Card hoverable bodyStyle={{ padding: 0 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', 
                    // border: '1px solid #e1e1e1', 
                    boxShadow: '0px 0px 7px 1px #f1f1e1', cursor: 'pointer'
                    }} onClick={() => { navigate(p.link) }}>
                      {/* <CardActionArea focusHighlight={false} disableRipple sx={{ display: 'flex', justifyContent: 'center' }}> */}
                        {/* <Box> */}
                          <CardContent sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>{p.icon}</div>
                            <Typography component="div" variant="body1" align='center'>
                              {p.title}
                            </Typography>

                          </CardContent>
                        {/* </Box> */}
                      {/* </CardActionArea> */}
                    </Card>
                  </Grid>
                ))

              }

              {/* 
              <Grid item sm={6} xs={6} alignItems={'center'} display={'flex'} flexDirection={'column'}>
                <MuiTypography color={'GrayText'} variant='caption'>Telefonu</MuiTypography>
                <MuiTypography variant='body1'>{phoneNumber}</MuiTypography>
              </Grid>
              <Grid item sm={6} xs={6} alignItems={'center'} display={'flex'} flexDirection={'column'}>
                <MuiTypography color={'GrayText'} variant='caption'>Katıldığı İl</MuiTypography>
                <MuiTypography variant='body1'>{provionce}</MuiTypography>
              </Grid> */}
            </Grid>

          </CardContent>
        </MuiCard>
      </Container>
    </BasePage>
  )
}

export default GuestDashboard