import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { ServiceHelper } from '../utils/service-helper';
import AddIcon from '@mui/icons-material/Add';

function ParameterComponent({ code, eventId, title, onChange, parameterValue }) {
  const [parameter, setParameters] = useState();

  useEffect(() => {
    getParamaters();
  }, []);

  const handleOnChange = (event) => {
    onChange(event.target.value)
  };

  async function getParamaters() {
    const response = await ServiceHelper.get(`/parameter/getAll/${code}/${eventId}`);
    setParameters(response.data.result);
  }

  return (
    <Stack display={'flex'} direction={'row'}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={parameterValue == null ? '' : parameterValue}
          defaultValue={''}
          label={title}
          onChange={handleOnChange}
        >
          {
            parameter?.map((item, key) => {
              return <MenuItem key={`${item.id}_${key}`} value={item.ParameterItem?.parameterCode}>{item.ParameterItem?.parameterValue}</MenuItem>;
            })
          }
        </Select>
      </FormControl>
      {/* <Box sx={{ display: 'flex', alignItems: 'center'}} width={48}>
        <IconButton color="primary" aria-label="add to shopping cart" >
          <AddIcon />
        </IconButton>
      </Box> */}

    </Stack>
  )
}

export default ParameterComponent