import { Container } from '@mui/material'
import React, { useEffect } from 'react'
import EventQrReader from '../../components/event-qr-reader'
import BasePage from '../core/base-page'

function ScanQr() {
  useEffect(() => {
  
  },[])

  return (
    <BasePage maxWidth={'sm'}>
      <Container maxWidth="sm" sx={{}} style={{ padding: 8, justifyContent: 'center' }}>
        <EventQrReader onRead={(link) => { window.location.href = link }} />        
      </Container>
    </BasePage>
  )
}

export default ScanQr