import React, { useEffect, useState } from 'react'
import BasePage from '../core/base-page'
import { Button, Box, Checkbox, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography, LinearProgress, Stack } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { Space, Upload, message, Button as AntButton, Table, Image, Card } from 'antd'
import SubmitButton from '../../components/submit-button'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getSession } from '../../utils/auth-helper'
import { ServiceHelper } from '../../utils/service-helper'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../app/reducers/user'
import { statusSlice } from '../../app/reducers/status'
import dayjs from 'dayjs'
import ParameterComponent from '../../components/parameter-component'
import RichTextEditor from '../../components/rich-text-editor'
import { getEventGroupName, getEventTypeName } from '../../utils/event-helper'
import CardHeaderTitle from '../../components/card-header-title'



function Event() {

  useEffect(() => {
    getOrganizations();
  }, []);

  async function getAll(organizationId) {
    setEventLoading(true);
    // const { id } = await getSession();
    const response = await ServiceHelper.get(`/event/getAll/${organizationId}`);
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      // dispatch(statusSlice.actions.setStatus(response))
      setEventList(response.data.result.map(p => ({ ...p, key: p.id })));
      setEventLoading(false);
    }
  }

  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [imagePath, setImagePath] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [selectedOrganzation, setOrganization] = useState('');
  const [organizationList, setOrganizationList] = useState();
  const [eventList, setEventList] = useState([]);
  const [eventLoading, setEventLoading] = useState(false);
  const [selectedEventGroup, setEventGroup] = useState('');
  const [selectedEventType, setEventType] = useState('');
  const [inputs, setInputs] = useState({
    name: '',
    description: '',
    eventPoint: '',
    quota: ''
  });
  const [inputErrors, setInputErrors] = useState({
    nameError: false,
    descriptionError: false,
    eventPointError: false
  })

  const { name, description, eventPoint, quota } = inputs;
  const { nameError, descriptionError, eventPointError } = inputErrors;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs(inputValues => ({ ...inputValues, [name]: value }));
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChangeImage = (info) => {

    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
        setImagePath(info.file.originFileObj);
      });
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Organizasyon Fotoğrafı
      </div>
    </div>
  );
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };


  const clearData = () => {
    setUpdateId(null);
    setInputs({
      name: "",
      description: "",
      eventPoint: "",
      quota: ""
    });
    setImagePath("");
    setStartDate(dayjs(new Date()));
    setEndDate(dayjs(new Date()));
    setIsActive(true);
    setIsOpen(true);
    // inputImageRef.current.value = null;
    setEventGroup('');
    setEventType('');
    setSubmitted(false);
  }

  const handleOrganizationChange = (event) => {
    setOrganization(event.target.value);
    getAll(event.target.value);
  };

  async function getOrganizations() {
    const { id } = await getSession();
    const response = await ServiceHelper.get(`/organization/getAll/${id}`);
    // dispatch(statusSlice.actions.setStatus(response))
    // console.log(response);
    setOrganizationList(response.data.result);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setInputErrors({
      nameError: false,
      descriptionError: false
    })
    if (name == "") {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, nameError: true }))
    }
    if (description == "") {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, descriptionError: true }))
    }
    if (selectedEventType > 0 && eventPoint == "") {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, eventPointError: true }))
    }

    if (name && description) {

      setSubmitted(true);
      const formData = new FormData();
      formData.append('file', imagePath);
      formData.append('name', name);
      formData.append('description', description);
      formData.append('isActive', isActive);
      formData.append('isOpen', isOpen);
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
      formData.append('ownerId', user.id);
      formData.append('organizationId', selectedOrganzation);
      formData.append('eventGroup', selectedEventGroup);
      formData.append('eventType', selectedEventType);
      formData.append('eventPoint', eventPoint);
      formData.append('quota', quota);
      if (updateId) {
        formData.append('id', updateId);
        let response = await ServiceHelper.putWithImage('/event/update', formData);
        if (response.success == false) {
          console.log(response.error);
          dispatch(statusSlice.actions.setStatus(response))
        } else {
          await getAll(selectedOrganzation);
          // const { from } = location.state || { from: { pathname: "/" } };
          // dispatch(userSlice.actions.login(response.data));
          // Navigate(from.pathname);
        }
      }
      else {
        // formData.append('image', image);
        let response = await ServiceHelper.postWithImage('/event/save', formData)
        if (response.success == false) {
          console.log(response.error);
          dispatch(statusSlice.actions.setStatus(response))
        } else {
          await getAll(selectedOrganzation);
        }
      }
      clearData();
    }

  }

  const handleDelete = async (id) => {
    // console.log('ID: ', id);
    let response = await ServiceHelper.delete(`/event/deleteById/${id}`);
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      await getAll();
    }
  }


  const handleUpdate = async (id) => {
    console.log('ID: ', id);
    setUpdateId(id);
    let response = await ServiceHelper.get('/event/getById', { id })
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      const { name, description, eventGroup, eventType, eventPoint, startDate, endDate, isActive, isOpen, organizationId, quota } = response.data.result;
      setInputs({ name, description, eventPoint, quota });
      // setImage(imagePath);
      setStartDate(dayjs(startDate));
      setEndDate(dayjs(endDate));
      setIsActive(isActive);
      setIsOpen(isOpen);
      setOrganization(organizationId);
      setEventGroup(eventGroup);
      setEventType(eventType);
      // inputImageRef.current.value = imagePath;
      // const base64String = byteArrayToBase64(image.data);
      // setImage(byteArrayToBase64(image.data));

    }
  }

  const columns = [
    {
      title: 'Resim',
      key: 'image',
      render: (_, record) => (
        <Image
          width={48}
          src={ServiceHelper.getImageUrl(record.image)}
        />
      ),
    },
    {
      title: 'Etkinlik Adı',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Etkinlik Grubu',
      dataIndex: 'eventGroup',
      key: 'eventGroup',
      render: (_, record) => (
        <Typography>{getEventGroupName(record.eventGroup)}</Typography>
      ),
    },
    {
      title: 'Etkinlik Türü',
      dataIndex: 'eventType',
      key: 'eventType',
      render: (_, record) => (
        <Typography>{getEventTypeName(record.eventType)}</Typography>
      ),
    },
    // {
    //   title: 'Açıklama',
    //   dataIndex: 'description',
    //   key: 'description',
    // },
    {
      title: 'Aksiyon',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a>
          <a>Delete</a> */}
          <AntButton type="primary" onClick={() => { handleUpdate(record.id) }}>
            Düzenle
          </AntButton>
          <AntButton type="primary" danger onClick={() => { handleDelete(record.id) }}>
            Sil
          </AntButton>
        </Space>
      ),
    }
  ]
  return (
    <BasePage maxWidth={'md'}>
      <Container maxWidth="md" sx={{ mt: 2 }} >
        <Card bordered={false}>
          <CardHeaderTitle title={'Etkinlik Tanımla'} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Organizasyon</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOrganzation == null ? 0 : selectedOrganzation}
                  defaultValue={''}
                  label="Organizasyon"
                  onChange={handleOrganizationChange}
                >
                  {
                    organizationList?.map((item, key) => {
                      return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>;
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Etkinlik Adı"
                name="name"
                // autoComplete="name"
                // autoFocus
                onChange={handleChange}
                value={name}
                error={nameError}
                helperText={nameError && 'Zorunlu Alan'}
              />
              <FormControl fullWidth>
                <RichTextEditor value={description} onChange={(value) => { handleChange({ target: { name: 'description', value: value } }) }} placeHolder={"Etkinlik Açıklaması"} />
              </FormControl>
              {/* <TextField
                margin="normal"
                required
                fullWidth
                id="description"
                label="Etkinlik Açıklaması"
                name="description"
                autoComplete="description"
                // autoFocus
                onChange={handleChange}
                value={description}
                error={descriptionError}
                helperText={descriptionError && 'Zorunlu Alan'}
              /> */}
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6} >
                  <DateTimePicker value={startDate} onChange={(date) => { setStartDate(date) }} sx={{ width: '-webkit-fill-available' }} label="Başlangıç Zamanı" ampm={false} />
                </Grid>
                <Grid item xs={12} sm={6} display={'flex'}>
                  <DateTimePicker value={endDate} onChange={(date) => { setEndDate(date) }} sx={{ width: '-webkit-fill-available' }} label="Bitiş Zamanı" ampm={false} />
                </Grid>
                <Grid item xs={6} sm={6} >
                  <ParameterComponent code={'EventGroup'} title={'Etkinlik Grubu'} parameterValue={selectedEventGroup} onChange={(value) => { setEventGroup(value) }} />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    // margin="dense"
                    // required
                    fullWidth
                    id="Kontenjan"
                    label="Kontenjan"
                    name="quota"
                    // autoComplete="name"
                    // autoFocus
                    onChange={handleChange}
                    value={quota}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <ParameterComponent code={'EventType'} title={'Etkinlik Tipi'} parameterValue={selectedEventType} onChange={(value) => {
                    handleChange({ target: { name: 'eventPoint', value: '' } })
                    setEventType(value)
                  }} />

                </Grid>
                {selectedEventType == 2 &&
                  <Grid item xs={6} sm={6}>
                    <TextField
                      // margin="dense"
                      required
                      fullWidth
                      id="Etkinlik Puanı"
                      label="Etkinlik Puanı"
                      name="eventPoint"
                      // autoComplete="name"
                      // autoFocus
                      onChange={handleChange}
                      value={eventPoint}
                      error={eventPointError}
                      helperText={eventPointError && 'Zorunlu Alan'}
                    />
                  </Grid>
                }
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item sm={6}>
                  {/* <input ref={inputImageRef} type="file"
                    // value={imagePath}
                    onChange={convertToBlob} /> */}
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    multiple={false}
                    customRequest={dummyRequest}
                    beforeUpload={beforeUpload}
                    onChange={handleChangeImage}
                    maxCount={1}>
                    {
                      uploadButton
                    }
                  </Upload>

                </Grid>
                <Grid item sm={6}>
                  <Stack direction={'column'}>
                    <FormControlLabel control={<Checkbox checked={isActive} onChange={(event) => { setIsActive(event.target.checked); }} />} label="Aktif mi?" />
                    <FormControlLabel control={<Checkbox checked={isOpen} onChange={(event) => { setIsOpen(event.target.checked); }} />} label="Kayıt Açık mı?" />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              {/* <Stack sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }} direction={'row'}> */}

              <SubmitButton submitted={submitted} iconName={"Save"} variant={'outlined'} title={"Kaydet"} onClick={handleSubmit} />
              {/* <Button type="submit" variant="contained" >Kaydet</Button> */}
              <Button variant="outlined" sx={{ ml: 1 }} onClick={clearData} >TEMİZLE</Button>

              {/* </Stack> */}
            </Grid>
          </Grid>
        </Card>

        <Box sx={{ mt: 2 }} >

          <Card bordered={false} bodyStyle={{ padding: 0 }}>
            <CardHeaderTitle title={'Etkinlik Listesi'} disableMargin={true} />
            {
              eventList && eventList.length > 0 ?
                <Grid item xs={12} sm={12}>
                  <Table dataSource={eventList} columns={columns} scroll={{ x: 1 }} />
                </Grid>
                :
                eventLoading ? <Box sx={{ width: '100%' }}>
                  <LinearProgress color={'success'} />
                </Box> : <Typography>Kayıt bulunamadı</Typography>
            }
          </Card>


        </Box>
      </Container>
    </BasePage>
  )
}

export default Event