import React, { useEffect } from 'react'
import BasePage from '../core/base-page'
import { Container, Stack, Typography } from '@mui/material'
import TopNavigation from '../../components/top-navigation'
import { useLocation, useParams } from 'react-router-dom'
import { Card, Divider } from 'antd'
import dayjs from 'dayjs'

function EventDetail() {
  const location = useLocation(); // Read values passed on state
  const { event } = location.state; // Read values passed on state

  // useEffect(() => {
  //   getEventDetailById();
  // }, []);

  console.log('Event Params', event);

  return (
    <BasePage maxWidth={'md'}>
      <Container maxWidth="md" sx={{}} style={{ padding: 8, justifyContent: 'center' }}>
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <TopNavigation title={event.name} />
          <Stack direction={'row'} display={'flex'} justifyContent={'center'}>
            <Typography sx={{ mr: 4}} >Tarih: {dayjs(event.startDate).locale('tr').format("DD.MM.YYYY dddd")}</Typography>
            <Typography>Saat: {dayjs(event.startDate).format("HH:mm")}</Typography>
          </Stack>
          <Divider>Açıklama</Divider>          
          <div style={{ margin: '16px' }} dangerouslySetInnerHTML={{ __html: event.description }} />
        </Card>
      </Container>
    </BasePage>
  )
}

export default EventDetail