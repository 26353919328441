import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import BasePage from '../core/base-page';
import { Container } from '@mui/material';
import { Button, Card } from 'antd';
import CardHeaderTitle from '../../components/card-header-title';
import { ServiceHelper } from '../../utils/service-helper';
import { statusSlice } from '../../app/reducers/status';
import { useDispatch } from 'react-redux';

function SubmitQR() {
	const dispatch = useDispatch();
	const [guestInfo, setGuestInfo] = useState();
	const location = useLocation(); // Read values passed on state
	const { paramId, paramOrganizationId, type } = location.state; // Read values passed on state

	useEffect(() => {
		getGuestByGuidId(paramId);
	}, []);

	const getGuestByGuidId = async (paramId) => {
		const response = await ServiceHelper.get(`/guest/getByGuid/${paramId}`);
		if (response.success) {
			const { result } = response.data;
			if (!result) {
				dispatch(statusSlice.actions.setStatus({ message: 'Katılımcı bilgileri bulunamadı', type: 'warning', status: true }))
			} else {
				setGuestInfo(result);
				// showModal();
				// setGuestInfo(result);
				// // generateQRCode(guidId, result.organizationId)
				// const linkValue = ServiceHelper.getUrl(`admin/tracker/${result.organizationId}/${guidId}`);
				// setLink(linkValue)
				dispatch(statusSlice.actions.setStatus({ message: 'Katılımcı bilgileri getrildi', type: 'success', status: true }))
			}
		} else {
			dispatch(statusSlice.actions.setStatus({ message: 'Hata Oluştu', type: 'error', status: true }))
		}
	}

	const handleSubmit = () => {
		// attendGuestToEvent({
		// 	organizationId: 5,
		// 	guestId: 1,
		// 	friendGuestId: friendInfo.id
		// });

		trackGuest({ organizationId: paramOrganizationId, guestId: guestInfo.id });
		// navigate('/admin/guest-scan')
	};


	const handleCancel = () => {
		// setIsModalOpen(false);
	};

	const trackGuest = async (data) => {
		console.log("LogData", data);
		let response = await ServiceHelper.post('/organization/track', data);
		// const response = await ServiceHelper.post(`/guest/saveFriend`, data);
		if (response.success) {
			const { result } = response.data;
			console.log("Response", response);
			if (!result) {
				dispatch(statusSlice.actions.setStatus({ message: "Kayıt Doğrulandı", type: 'success', status: true }))
			} else {

				// setGuestInfo(result);
				// // generateQRCode(guidId, result.organizationId)
				// const linkValue = ServiceHelper.getUrl(`admin/tracker/${result.organizationId}/${guidId}`);
				// setLink(linkValue)
				dispatch(statusSlice.actions.setStatus({ message: 'Arkadaş Eklendi', type: 'success', status: true }))
			}
		} else {
			dispatch(statusSlice.actions.setStatus({ message: 'Hata Oluştu', type: 'error', status: true }))
		}
	}

	return (
		<BasePage maxWidth={'sm'}>
			<Container maxWidth="sm" sx={{}} style={{ padding: 8, justifyContent: 'center' }}>
				<Card bordered={false}>
					<CardHeaderTitle title={'Katılımcı Bilgileri'} />
					<p style={{ margin: 0, padding: 0 }}><span>Adı: </span>{guestInfo?.id}</p>
					<p style={{ margin: 0, padding: 0 }}><span>Adı: </span>{guestInfo?.firstName}</p>
					<p style={{ margin: 0, padding: 0 }}><span>Soyadı: </span>{guestInfo?.lastName}</p>
					<p style={{ margin: 0, padding: 0 }}><span>Telefon: </span>{guestInfo?.phoneNumber}</p>
					<p style={{ margin: 0, padding: 0 }}><span>İl: </span>{guestInfo?.provionce}</p>
					<Button onClick={handleSubmit}>Kaydet</Button>
					<Button onClick={handleCancel}>İptal</Button>
				</Card>
			</Container>
		</BasePage>
	)
}

export default SubmitQR