import { Grid, Typography } from '@mui/material'
import { blue, blueGrey, grey, lightBlue } from '@mui/material/colors'
import React from 'react'

function HeaderTitle({ title }) {
    const color = blueGrey[700]
    
    return (
        <Grid item sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center'}}>
            <Typography variant="h5" align="center" color={color}>{title}</Typography>
        </Grid>
    )
}

export default HeaderTitle