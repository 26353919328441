import { createSlice } from '@reduxjs/toolkit';
import { getSession, getSessionStatus } from '../../utils/auth-helper';

const initialState = {
  logginIn: getSessionStatus(),
  user: getSession()
};


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.logginIn = true;
      const data = action.payload;
      state.user = data.user;
      localStorage.setItem("token", data.token)
    },
    logout: (state) => {
      state.logginIn = false
    },
    register: (state) => {
      state.logginIn = true;
    },
    setUserInfo: (state, action) => {
      state.user = action.payload.data
    }
  }
})

export const selectUser = (state) => state.user;

export default userSlice.reducer;