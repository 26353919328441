import React, { useEffect, useState } from 'react'
import BasePage from '../core/base-page'
import { Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import EventQrReader from '../../components/event-qr-reader'
import { ServiceHelper } from '../../utils/service-helper'
import { getGuestSession } from '../../utils/auth-helper'
import { useDispatch } from 'react-redux'
import { statusSlice } from '../../app/reducers/status'
import { Modal } from 'antd'

function ScanQr() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [friendInfo, setFriendInfo] = useState();
    useEffect(() => {

    }, [])

    const onReadQr = async (link) => {
        let result = link.split('/');
        const paramId = result[result.length - 1];
        const paramOrganizationId = result[result.length - 2];
        const type = result[result.length - 3];
        console.log("Values", paramId, paramOrganizationId, type);
        switch (type) {
            case "profile":
                const { organizationId } = await getGuestSession();
                console.log("organizationId", organizationId, paramOrganizationId);
                if (paramOrganizationId != organizationId) {
                    dispatch(statusSlice.actions.setStatus({ message: 'Eklemek istediğiniz kişi aynı organizasyona kayıtlı değil!', type: 'warning', status: true }))
                }
                else {
                    getGuestByGuidId(paramId);
                }
                break;

            case "register":

                break;

            case "track":

                break;

            default:
                break;
        }

    }

    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = async () => {
      setIsModalOpen(false);
      const { organizationId, id } = await getGuestSession();
      addFriend({
        organizationId,
        guestId: id,
        friendGuestId: friendInfo.id
      });
      navigate('/guest/friends')
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };


    const addFriend = async (data) => {
        const response = await ServiceHelper.post(`/guest/saveFriend`, data);
        if (response.success) {
            const { result } = response.data;
            if (!result) {
                dispatch(statusSlice.actions.setStatus({ message: response.message, type: 'warning', status: true }))
            } else {
                
                // setGuestInfo(result);
                // // generateQRCode(guidId, result.organizationId)
                // const linkValue = ServiceHelper.getUrl(`admin/tracker/${result.organizationId}/${guidId}`);
                // setLink(linkValue)
                dispatch(statusSlice.actions.setStatus({ message: 'Arkadaş Eklendi', type: 'success', status: true }))
            }
        } else {
            dispatch(statusSlice.actions.setStatus({ message: 'Hata Oluştu', type: 'error', status: true }))
        }
    }

    const getGuestByGuidId = async (paramId) => {
        const response = await ServiceHelper.get(`/guest/getByGuid/${paramId}`);
        if (response.success) {
            const { result } = response.data;
            if (!result) {
                dispatch(statusSlice.actions.setStatus({ message: 'Katılımcı bilgileri bulunamadı', type: 'warning', status: true }))
            } else {
                setFriendInfo(result);
                showModal();
                // setGuestInfo(result);
                // // generateQRCode(guidId, result.organizationId)
                // const linkValue = ServiceHelper.getUrl(`admin/tracker/${result.organizationId}/${guidId}`);
                // setLink(linkValue)
                dispatch(statusSlice.actions.setStatus({ message: 'Katılımcı bilgileri getrildi', type: 'success', status: true }))
            }
        } else {
            dispatch(statusSlice.actions.setStatus({ message: 'Hata Oluştu', type: 'error', status: true }))
        }
    }

    return (
        <BasePage maxWidth={'sm'}>
            <Container maxWidth="sm" sx={{}} style={{ justifyContent: 'center' }}>
                <EventQrReader onRead={onReadQr} />
                <Modal title="Katılımcı Bilgileri" open={isModalOpen} onOk={handleOk} okText={'Ekle'} onCancel={handleCancel} cancelText={'İptal'}>
                    <p style={{ margin: 0, padding: 0 }}><span>Adı: </span>{friendInfo?.firstName}</p>
                    <p style={{ margin: 0, padding: 0 }}><span>Soyadı: </span>{friendInfo?.lastName}</p>
                    <p style={{ margin: 0, padding: 0 }}><span>Telefon: </span>{friendInfo?.phoneNumber}</p>
                    <p style={{ margin: 0, padding: 0 }}><span>İl: </span>{friendInfo?.provionce}</p>
                </Modal>
            </Container>
        </BasePage>
    )
}

export default ScanQr