import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import LoginIcon from '@mui/icons-material/Login';

function SubmitButton({ title, submitted, variant, iconName, fullWidth, onClick }) {
	return (
		<LoadingButton			
			loading={submitted}
			loadingPosition="start"
			startIcon={getIcon(iconName)}//{<SaveIcon />}
			variant={variant}//"outlined"
			type='submit'
			fullWidth={fullWidth}
			onClick={onClick}
		>
			{title}
		</LoadingButton>
	)
}

function getIcon(iconName) {
	switch (iconName) {
		case "Save":
			return <SaveIcon />
		case "Login":
			return <LoginIcon />	
		default:
			return null;
	}
}

export default SubmitButton