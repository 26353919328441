import { useState } from 'react'
import BasePage from '../core/base-page';
import { Box, Button, Container, FormControl, FormControlLabel, Grid, InputLabel, LinearProgress, Select, TextField, Typography } from '@mui/material';
import { Card, Space, Table, Button as AntButton } from 'antd';
import RichTextEditor from '../../components/rich-text-editor';
import ParameterComponent from '../../components/parameter-component';
import SubmitButton from '../../components/submit-button';
import OrganizationSelect from '../../components/organization-select';
import { ServiceHelper } from '../../utils/service-helper';
import { useDispatch } from 'react-redux';
import { statusSlice } from '../../app/reducers/status';
import { getEventGroupName } from '../../utils/event-helper';
import CardHeaderTitle from '../../components/card-header-title';

function Announcement() {
  const [updateId, setUpdateId] = useState();
  const [announcementLoading, setAnnouncementLoading] = useState(false);
  const [announcementList, setAnnouncementList] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [selectedEventGroup, setEventGroup] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    name: '',
    description: '',
  });

  const [inputErrors, setInputErrors] = useState({
    nameError: false,
    descriptionError: false
  })

  const { name, description } = inputs;
  const { nameError, descriptionError } = inputErrors;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs(inputValues => ({ ...inputValues, [name]: value }));
  }

  const handleOrganizationChange = (value) => {
    setSelectedOrganization(value);
    getAll(value);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    setInputErrors({
      nameError: false,
      descriptionError: false
    })
    if (name == "") {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, nameError: true }))
    }
    if (description == "") {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, descriptionError: true }))
    }

    if (name && description) {

      setSubmitted(true);
      let requestBody = { name, description, organizationId: selectedOrganization, eventGroup: selectedEventGroup };

      if (updateId) {
        requestBody = Object.assign({}, requestBody, { id: updateId });
        let response = await ServiceHelper.put('/announcement/update', requestBody);
        if (response.success === false) {
          console.log(response.error);
          dispatch(statusSlice.actions.setStatus(response))
        } else {
          await getAll(selectedOrganization);
        }
      }
      else {
        let response = await ServiceHelper.post('/announcement/save', requestBody)
        if (response.success === false) {
          console.log(response.error);
          dispatch(statusSlice.actions.setStatus(response))
        } else {
          await getAll(selectedOrganization);
        }
      }
      clearData();
    }

  }

  const clearData = () => {
    setUpdateId(null);
    setInputs({
      name: "",
      description: "",
    });
    setEventGroup('');
    setSelectedOrganization('');
    setSubmitted(false);
  }

  const handleDelete = async (id) => {
    let response = await ServiceHelper.delete(`/announcement/deleteById/${id}`);
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      await getAll();
    }
  }


  const handleUpdate = async (id) => {
    console.log('ID: ', id);
    setUpdateId(id);
    let response = await ServiceHelper.get('/announcement/getById', { id })
    if (response.success === false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      const { name, description, eventGroup, organizationId } = response.data.result;
      setInputs({ name, description });
      setSelectedOrganization(organizationId);
      setEventGroup(eventGroup);
    }
  }

  async function getAll(organizationId) {
    setAnnouncementLoading(true);
    // const { id } = await getSession();
    const response = await ServiceHelper.get(`/announcement/getAll/${organizationId}`);
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      // dispatch(statusSlice.actions.setStatus(response))
      setAnnouncementList(response.data.result.map(p => ({ ...p, key: p.id })));
      setAnnouncementLoading(false);
    }
  }


  const columns = [
    {
      title: 'Duyuru Başlığı',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Etkinlik Grubu',
      dataIndex: 'eventGroup',
      key: 'eventGroup',
      render: (_, record) => (
        <Typography>{getEventGroupName(record.eventGroup)}</Typography>
      ),
    },
    {
      title: 'Aksiyon',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a>
          <a>Delete</a> */}
          <AntButton type="primary" onClick={() => { handleUpdate(record.id) }}>
            Düzenle
          </AntButton>
          <AntButton type="primary" danger onClick={() => { handleDelete(record.id) }}>
            Sil
          </AntButton>
        </Space>
      ),
    }
  ]


  return (
    <BasePage maxWidth={'md'}>
      <Container maxWidth="md" sx={{ mt: 2 }} >
      <Card bordered={false}>
        <CardHeaderTitle title={'Duyuru Tanımla'} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <OrganizationSelect onChange={handleOrganizationChange} selectedOrganization={selectedOrganization} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Duyuru Başlığı"
                name="name"
                onChange={handleChange}
                value={name}
                error={nameError}
                helperText={nameError && 'Zorunlu Alan'}
              />
              <FormControl fullWidth>
                <RichTextEditor value={description} onChange={(value) => { handleChange({ target: { name: 'description', value: value } }) }} placeHolder={"Duyuru Açıklaması"} />
              </FormControl>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6} sm={6} >
                  <ParameterComponent code={'EventGroup'} title={'Duyuru Grubu'} parameterValue={selectedEventGroup} onChange={(value) => { setEventGroup(value) }} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <SubmitButton submitted={submitted} iconName={"Save"} variant={'outlined'} title={"Kaydet"} onClick={handleSubmit} />
              <Button variant="outlined" sx={{ ml: 1 }} onClick={clearData} >TEMİZLE</Button>
            </Grid>
          </Grid>
        </Card>
       
        <Box sx={{ mt: 2 }} >

          <Card bordered={false} bodyStyle={{ padding: 0 }}>
            <CardHeaderTitle title={'Duyuru Listesi'} disableMargin={true} />
            {
              announcementList && announcementList.length > 0 ?
                  <Table dataSource={announcementList} columns={columns} scroll={{ x: 1 }} />
                
                :
                announcementLoading ? <Box sx={{ width: '100%' }}>
                  <LinearProgress color={'success'} />
                </Box> : <Typography>Kayıt bulunamadı</Typography>
            }
          </Card>
        </Box>
      </Container>
    </BasePage>
  )
}

export default Announcement