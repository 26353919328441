import React from 'react';
import { Routes, Route } from "react-router-dom";
import { PrivateGuestRoute, PrivateRoute } from './utils/private-route';
import Navbar from './components/navbar';
import Home from './pages/home';
import Profile from './pages/admin/profile';
import Login from './pages/admin/login';
import Register from './pages/admin/register';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Organization from './pages/admin/organization';
import Guest from './pages/admin/guest';
import Places from './pages/place';
import PlaceDetail from './pages/place-detail';
import { useSelector } from 'react-redux';
import { selectUser, userSlice } from './app/reducers/user';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/tr';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import GuestLogin from './pages/guest/guest-login';
import GuestProfile from './pages/guest/guest-profile';
import Timeline from './pages/guest/timeline';
import Event from './pages/admin/event';
import Tracker from './pages/admin/tracker';
import GuestDashboard from './pages/guest/guest-dashboard';
import AdminDashBoard from './pages/admin/admin-dashboard';
import Friends from './pages/guest/friends';
import Events from './pages/guest/events';
import GuestAnnouncement from './pages/guest/announcement';
import Announcement from './pages/admin/announcement';
import ScanQr from './pages/admin/scan-qr';
import ScanGuestQr from './pages/admin/scan-guest-rq';
import GuestScanQr from './pages/guest/scan-qr';
import EventDetail from './pages/guest/event-detail';
import Attendee from './pages/admin/attendee';
import SubmitQR from './pages/admin/submit-qr';


dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');

// --ecru: #DBB780ff;
// --antiflash-white: #EAECEEff;
// --platinum: #DDE0E7ff;
// --french-gray: #B7B9D3ff;
// --glaucous: #696FABff;

// --earth-yellow: #D59E49ff;
// --ecru: #DBB780ff;
// --platinum: #DDE0E7ff;
// --glaucous: #696FABff;
// --violet-blue: #4B529Bff;
function App() {
  const theme = createTheme({
    palette: {
      primary: {
        light: '#B7B9D3ff',
        main: '#4B529Bff',
        dark: '#292D5Eff',
        contrastText: '#fff',
      },
      secondary: {
        main: '#D59E49ff',
        light: '#DBB780ff',
        dark: '#347564',
        contrastText: '#000',
      },
    },
  });
  const user = useSelector(selectUser);
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='tr'>
        <CssBaseline />
        {/* {user.logginIn && <Navbar />} */}
        <Navbar />
        <AppRoutes />
        {/* <SpeedDial
            ariaLabel="Whatsapp Destek"
            color='success'
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            onClick={() => {
              let url = `whatsapp://send?text=${encodeURIComponent("Selamunaleyküm,\nBir desteğe ihtiyacım var.")}&phone=905076965486`
              window.open(url);
            }}
            icon={<WhatsAppIcon />}
          ></SpeedDial> */}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

function AppRoutes() {
  return <Routes>
    {/* Main Routes */}
    <Route exact path='/' element={<Home />} />
    
    {/* Admin Routes */}
    <Route path='/admin/tracker/:organizationId/:guidId' element={<PrivateRoute />}>
      <Route path='/admin/tracker/:organizationId/:guidId' element={<Tracker />} />
    </Route>
    <Route path='/admin/dashboard' element={<PrivateRoute />}>
      <Route path='/admin/dashboard' element={<AdminDashBoard />} />
    </Route>
    <Route path='/admin/profile' element={<PrivateRoute />}>
      <Route path='/admin/profile' element={<Profile />} />
    </Route>
    <Route path='/admin/organization' element={<PrivateRoute />}>
      <Route path='/admin/organization' element={<Organization />} />
    </Route>
    <Route path='/admin/event' element={<PrivateRoute />}>
      <Route path='/admin/event' element={<Event />} />
    </Route>
    <Route path='/admin/guest' element={<PrivateRoute />}>
      <Route path='/admin/guest' element={<Guest />} />
    </Route>
    <Route path='/admin/announcement' element={<PrivateRoute />}>
      <Route path='/admin/announcement' element={<Announcement />} />
    </Route>
    <Route path='/admin/attendee' element={<PrivateRoute />}>
      <Route path='/admin/attendee' element={<Attendee />} />
    </Route>
    <Route path='/admin/scan' element={<PrivateRoute />}>
      <Route path='/admin/scan' element={<ScanQr />} />
    </Route>
    <Route path='/admin/guest-scan' element={<PrivateRoute />}>
      <Route path='/admin/guest-scan' element={<ScanGuestQr />} />
    </Route>
    <Route path='/admin/submit-qr' element={<PrivateRoute />}>
      <Route path='/admin/submit-qr' element={<SubmitQR />} />
    </Route>

    {/* Guest Routes */}
    <Route path='/guest/dashboard' element={<PrivateGuestRoute />}>
      <Route path='/guest/dashboard' element={<GuestDashboard />} />
    </Route>
    <Route path='/guest/timeline' element={<PrivateGuestRoute />}>
      <Route path='/guest/timeline' element={<Timeline />} />
    </Route>
    <Route path='/guest/friends' element={<PrivateGuestRoute />}>
      <Route path='/guest/friends' element={<Friends />} />
    </Route>
    <Route path='/guest/events' element={<PrivateGuestRoute />}>
      <Route path='/guest/events' element={<Events />} />
    </Route>
    <Route path='/guest/announcement' element={<PrivateGuestRoute />}>
      <Route path='/guest/announcement' element={<GuestAnnouncement />} />
    </Route>
    <Route path='/guest/scan' element={<PrivateGuestRoute />}>
      <Route path='/guest/scan' element={<GuestScanQr />} />
    </Route>
    <Route path='/guest/event-detail' element={<PrivateGuestRoute />}>
      <Route path='/guest/event-detail' element={<EventDetail />} />
    </Route>

    {/* Public Routes */}
    <Route path='/guest/profile/:guidId' element={<GuestProfile />} />
    <Route path="guest/login" element={<GuestLogin />} />
    <Route path="admin/login" element={<Login />} />
    <Route path="admin/register" element={<Register />} />
  </Routes>
}

export default App;
