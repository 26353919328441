import { createSlice } from '@reduxjs/toolkit';
import { getGuestSession, getGuestSessionStatus, getSession, getSessionStatus } from '../../utils/auth-helper';

const initialState = {
  guestLogginIn: getGuestSessionStatus(),
  guest: getGuestSession()
};


export const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    login: (state, action) => {
      state.guestLogginIn = true;
      const data = action.payload;
      state.guest = data.guest;
      localStorage.setItem("guest-token", data.token)
    },
    logout: (state) => {
      state.guestLogginIn = false
    },
    // register: (state) => {
    //   state.guestLogginIn = true;
    // },
    setGuestInfo: (state, action) => {
      state.guest = action.payload.data
    }
  }
})

export const selectGuest = (state) => state.guest;

export default guestSlice.reducer;