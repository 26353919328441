import { Card } from 'antd';
import React from 'react'
import { QrReader } from 'react-qr-reader';

function EventQrReader({ onRead }) {

  const onScan = async (result, error) => {
    // console.log("Result:", result);
    // console.log("Error:", error);
    if (!!result) {
      onRead(result?.text);
    }

    if (!!error) {
      // console.info(error);
    }
  }

  return (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
      <QrReader
        constraints={{
          facingMode: 'environment'
        }}
        scanDelay={1000}
        onResult={onScan}
      />
    </Card>
  )
}

export default EventQrReader