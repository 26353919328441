import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, userSlice } from '../app/reducers/user';
import { useTheme, styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import ReportIcon from '@mui/icons-material/ListRounded';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import LogoutIcon from '@mui/icons-material/Logout';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/Inbox';
import Stack from '@mui/material/Stack';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { Box, ListItemButton } from '@mui/material';
import Logo from '../assets/images/mec.webp';
import { guestSlice, selectGuest } from '../app/reducers/guest';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


function Navbar() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const guest = useSelector(selectGuest);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = (loginType) => {
    // console.log('guest', guest);
    handleClose();
    loginType == 'Admin' ? navigate('/admin/profile') : loginType == 'Guest' ? navigate(`/guest/profile/${guest.guest.guidId}`) : navigate('/');
  };
  const handleLogut = (loginType) => {
    handleClose();
    if (loginType == 'Admin') {
      dispatch(userSlice.actions.logout());
    } else if (loginType == 'Guest') {
      dispatch(guestSlice.actions.logout());
    }
    localStorage.clear();
    navigate('/')
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onMenuItemClicked = ({ path }) => {
    navigate(path);
    setOpen(false);
  }

  const getLoginType = () => {
    // console.log('guest: ', guest);
    if (user.logginIn) {
      return 'Admin';
    } else if (guest.guestLogginIn) {
      return 'Guest'
    } else {
      return 'Anonymous'
    }
  }

  const loginType = getLoginType();

  const rightMenu = () => {
    // console.log('loginType', loginType);
    if (loginType == 'Admin' || loginType == 'Guest') {
      return (
        <div>
          {loginType == 'Admin' && <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => onMenuItemClicked({ path: '/admin/guest-scan' })}
            color="inherit"
          >
            <QrCodeScannerIcon />
          </IconButton>

          }
          {
            loginType == "Admin" ?
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              :
              <>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => onMenuItemClicked({ path: '/guest/scan' })}
                  color="inherit"
                >
                  <QrCodeScannerIcon />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => {
                    // handleProfile(loginType) 
                    let url = `whatsapp://send?text=${encodeURIComponent("Selamunaleyküm,\nBir desteğe ihtiyacım var.")}&phone=905334732970`
                    window.open(url);
                  }}
                  color="inherit"
                >
                  <WhatsAppIcon />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => { handleLogut(loginType) }}
                  color="inherit"
                >
                  <LogoutIcon />
                </IconButton>
              </>
          }


          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => { handleProfile(loginType) }}>Bilgilerim</MenuItem>
            <MenuItem onClick={() => { handleLogut(loginType) }}>Çıkış</MenuItem>
          </Menu>
        </div>
      )
    } else {
      return <></>;
      // return <Stack direction="row">
      //   <Button color="inherit" onClick={() => { navigate('/admin/login') }}>GİRİŞ</Button>
      //   <Button color="inherit" onClick={() => { navigate('/guest/login') }}>MİSAFİR</Button>
      // </Stack>
    }
  }
  const  overFlowStyle = { 
    textOverflow: 'ellipsis',
    overflow: 'hidden', 
    width: '240px', 
    // height: 1.2em; 
    whiteSpace: 'nowrap'
  };

  return (
    // <AppBar position="static">
    <>
      <AppBar position="fixed" style={{
        // backgroundColor: '#093f53',
        // height: 48 
        boxShadow: 'none'
      }} open={open}>
        <Toolbar>
          {!user.logginIn ?
            // <IconButton
            //   size="large"
            //   edge="start"
            //   color="inherit"
            //   aria-label="menu"
            //   sx={{ mr: 2 }}
            //   onClick={() => { navigate('/guest/dashboard') }}
            // >
            //   <HomeIcon />
            // </IconButton>
            <></>
            :
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
          }
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} >Canlı Rehberim</Typography> */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            // pt: 1,
            // pb: 1,
            // mb: 1,
            // bgcolor: '#093f53',
            // boxShadow: 3,
            flexGrow: 1,
            // height: 64
          }}>
            { user.logginIn ? <div style={overFlowStyle}>SERVER Yaşam Vakfı</div> :<div>SERVER Yaşam Vakfı</div>}
            {/* <img src={Logo} alt='Logo' height={36} /> */}
          </Box>
          {
            rightMenu()
          }
          {/* {!user.logginIn && (
            <Stack direction="row">
              <Button color="inherit" onClick={() => { navigate('/admin/login') }}>GİRİŞ</Button>
              <Button color="inherit" onClick={() => { navigate('/guest/login') }}>MİSAFİR</Button>
            </Stack>
          )}
          {user.logginIn && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleProfile}>Bilgilerim</MenuItem>
                <MenuItem onClick={handleLogut}>Çıkış</MenuItem>
              </Menu>
            </div>
          )} */}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open && user.logginIn}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItemButton key={'Ana Sayfa'} onClick={() => { onMenuItemClicked({ path: '/admin/dashboard' }) }}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Ana Sayfa'} />
          </ListItemButton>
        </List>
        <Divider />
        <List>
          <ListItemButton key={'Organization'} onClick={() => { onMenuItemClicked({ path: '/admin/organization' }) }}>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary={'Organizasyonlar'} />
          </ListItemButton>
          <ListItemButton key={'Etkinlikler'} onClick={() => { onMenuItemClicked({ path: '/admin/event' }) }}>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary={'Etkinlikler'} />
          </ListItemButton>
          <ListItemButton key={'Guests'} onClick={() => { onMenuItemClicked({ path: '/admin/guest' }) }}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={'Katılımcılar'} />
          </ListItemButton>
          <ListItemButton key={'Attendee'} onClick={() => { onMenuItemClicked({ path: '/admin/attendee' }) }}>
            <ListItemIcon>
              <ReportIcon />
            </ListItemIcon>
            <ListItemText primary={'Rapor'} />
          </ListItemButton>
          {/* <ListItemButton key={'Information'} onClick={() => { onMenuItemClicked({ path: '/admin/announcement' }) }}>
            <ListItemIcon>
              <AnnouncementIcon />
            </ListItemIcon>
            <ListItemText primary={'Duyurular'} />
          </ListItemButton> */}
        </List>
        <Divider />
        <List>
          <ListItemButton key={'Scan'} onClick={() => { onMenuItemClicked({ path: '/admin/guest-scan' }) }}>
            <ListItemIcon>
              <QrCodeScannerIcon />
            </ListItemIcon>
            <ListItemText primary={'QR Kod Tarama'} />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  )
}

export default Navbar
