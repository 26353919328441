import { Box, Button, CardActionArea, CardActions, CardContent, CardMedia, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, LinearProgress, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { statusSlice } from '../../app/reducers/status';
import { selectUser } from '../../app/reducers/user';
import DefaultImage from '../../components/icons/default-image';
import { ServiceHelper } from '../../utils/service-helper';
import BasePage from '../core/base-page';
import SubmitButton from '../../components/submit-button';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Space, Table, Upload, Button as AntButton, Image, Card } from 'antd';
import dayjs from 'dayjs';
import { getSession } from '../../utils/auth-helper';
import RichTextEditor from '../../components/rich-text-editor';
import TopNavigation from '../../components/top-navigation';
import CardHeaderTitle from '../../components/card-header-title';

function Organization() {
  const inputImageRef = useRef(null);
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const [updateId, setUpdateId] = useState();
  const [image, setImage] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [inputs, setInputs] = useState({
    name: '',
    description: ''
  });
  const [inputErrors, setInputErrors] = useState({
    nameError: false,
    descriptionError: false
  })
  const [submitted, setSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [imageUrl, setImageUrl] = useState();
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));



  const { name, description } = inputs;
  const { nameError, descriptionError } = inputErrors;

  useEffect(() => {
    // console.log('Effect');
    let response = getAll()
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      // const { from } = location.state || { from: { pathname: "/" } };
      // dispatch(userSlice.actions.login(response.data));
      // Navigate(from.pathname);
    }



  }, []);

  async function getAll() {
    setCategoryLoading(true);
    const { id } = await getSession();
    const response = await ServiceHelper.get(`/organization/getAll/${id}`);
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      // dispatch(statusSlice.actions.setStatus(response))
      setCategoryList(response.data.result.map(p => ({ ...p, key: p.id })));
      setCategoryLoading(false);
    }


  }

  const convertToBlob = (e) => {
    setImagePath(e.target.files[0]);

    // let image = URL?.createObjectURL(e.target.files[0]);
    // setFile(image);

    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      // console.log(reader.result);
      setImage(reader.result);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputValues => ({ ...inputValues, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setInputErrors({
      nameError: false,
      descriptionError: false
    })
    if (name == "") {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, nameError: true }))
    }
    if (description == "") {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, descriptionError: true }))
    }

    if (name && description) {
      setSubmitted(true);

      const formData = new FormData();
      formData.append('file', imagePath);
      formData.append('name', name);
      formData.append('description', description);
      formData.append('isActive', isActive);
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
      formData.append('userId', user.id);
      if (updateId) {
        formData.append('id', updateId);
        let response = await ServiceHelper.putWithImage('/organization/update', formData);
        if (response.success == false) {
          console.log(response.error);
          dispatch(statusSlice.actions.setStatus(response))
        } else {
          await getAll();
          // const { from } = location.state || { from: { pathname: "/" } };
          // dispatch(userSlice.actions.login(response.data));
          // Navigate(from.pathname);
        }
      }
      else {
        // formData.append('image', image);
        let response = await ServiceHelper.postWithImage('/organization/save', formData)
        if (response.success == false) {
          console.log(response.error);
          dispatch(statusSlice.actions.setStatus(response))
        } else {
          await getAll();
        }
      }
      clearData();
    }
  }

  const clearData = () => {
    setUpdateId(null);
    setInputs({
      name: "",
      description: ""
    });

    setInputErrors({
      nameError: false,
      descriptionError: false
    })
    setImage("");
    setImagePath("");
    setStartDate(dayjs(new Date()));
    setEndDate(dayjs(new Date()));
    setIsActive(true);
    // inputImageRef.current.value = null;

    setSubmitted(false);
  }

  const handleDelete = async (id) => {
    // console.log('ID: ', id);
    let response = await ServiceHelper.delete(`/organization/deleteById/${id}`);
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      await getAll();
    }
  }

  const handleUpdate = async (id) => {
    console.log('ID: ', id);
    setUpdateId(id);
    let response = await ServiceHelper.get('/organization/getById', { id })
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      const { name, description, imagePath, startDate, endDate, isActive } = response.data.result;
      setInputs({ name, description });
      // setImage(imagePath);
      // setImageUrl(imagePath);
      setImagePath(imagePath);
      setStartDate(dayjs(startDate));
      setEndDate(dayjs(endDate));
      setIsActive(isActive);
      // inputImageRef.current.value = imagePath;
      // const base64String = byteArrayToBase64(image.data);
      // setImage(byteArrayToBase64(image.data));

    }
  }
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange2 = (info) => {

    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        // setImageUrl(url);
        setImagePath(info.file.originFileObj);
      });
    }
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Organizasyon Fotoğrafı
      </div>
    </div>
  );

  const columns = [
    {
      title: 'Resim',
      key: 'image',
      render: (_, record) => (
        <Image
          height={36}
          src={ServiceHelper.getImageUrl(record.imagePath)}
        />
      ),
    },
    {
      title: 'Etkinlik Adı',
      dataIndex: 'name',
      key: 'name',
    },
    // {
    //   title: 'Açıklama',
    //   dataIndex: 'description',
    //   key: 'description',
    // },
    {
      title: 'Aksiyon',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a>
          <a>Delete</a> */}
          <AntButton key={'update'} type="primary" onClick={() => { handleUpdate(record.id) }}>
            Düzenle
          </AntButton>
          <AntButton key={'delete'} type="primary" danger onClick={() => { handleDelete(record.id) }}>
            Sil
          </AntButton>
        </Space>
      ),
    }
  ]
  // console.log("isActive: ", isActive)
  return (
    <BasePage maxWidth={'md'}>
      <Container maxWidth="md" sx={{ mt: 2 }} >
        <Card bordered={false}>
          <CardHeaderTitle title={'Organizasyon Tanımla'} />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Organizasyon Adı"
                name="name"
                // autoComplete="name"
                // autoFocus
                onChange={handleChange}
                value={name}
                error={nameError}
                helperText={nameError && 'Zorunlu Alan'}
              />
              <FormControl fullWidth>
                <RichTextEditor value={description} onChange={(value) => { handleChange({ target: { name: 'description', value: value } }) }} placeHolder={"Organizasyon Açıklaması"} />
              </FormControl>
              {/* <TextField
                margin="normal"
                required
                fullWidth
                id="description"
                label="Organizasyon Açıklaması"
                name="description"
                autoComplete="description"
                // autoFocus
                onChange={handleChange}
                value={description}
                error={descriptionError}
                helperText={descriptionError && 'Zorunlu Alan'}
              /> */}
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6} sm={6} >
                  <DateTimePicker value={startDate} onChange={(date) => { setStartDate(dayjs(date)) }} sx={{ width: '-webkit-fill-available' }} label="Etkinlik Başlangıç Zamanı" ampm={false} />
                </Grid>
                <Grid item xs={6} sm={6} display={'flex'}>
                  <DateTimePicker value={endDate} onChange={(date, validation) => {
                    console.log("validation", validation);
                    validation.validationError != "invalidDate" &&
                      setEndDate(dayjs(date))
                  }} sx={{ width: '-webkit-fill-available' }} label="Etkinlik Bitiş Zamanı" ampm={false} />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item sm={6}>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    multiple={false}
                    customRequest={dummyRequest}
                    beforeUpload={beforeUpload}
                    onChange={handleChange2}
                    maxCount={1}
                  // itemRender={() => <img src={ServiceHelper.getImageUrl(imagePath)} width={64} />}
                  // fileList={!imagePath  ? undefined : !updateId ? undefined : [{ url: ServiceHelper.getImageUrl(imagePath)}]}
                  >
                    {

                      uploadButton
                    }
                  </Upload>

                </Grid>
                <Grid item sm={6}>
                  <FormControlLabel control={<Checkbox checked={isActive} onChange={(event) => { setIsActive(event.target.checked); }} />} label="Aktif mi?" />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              {/* <Stack sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }} direction={'row'}> */}

              <SubmitButton submitted={submitted} iconName={"Save"} variant={'outlined'} title={"Kaydet"} onClick={handleSubmit} />
              {/* <Button type="submit" variant="contained" >Kaydet</Button> */}
              <Button variant="outlined" sx={{ ml: 1 }} onClick={clearData} >TEMİZLE</Button>

              {/* </Stack> */}
            </Grid>
          </Grid>
          {/* </Stack> */}
        </Card>
        <Box sx={{ mt: 2 }}>
          {/* <Grid container maxWidth={'md'} spacing={2} sx={{ mt: 2 }} > */}
            <Card bordered={false} bodyStyle={{ padding: 0 }}>
              <CardHeaderTitle title={'Organizasyon Listesi'} disableMargin={true} />
              {
                categoryList && categoryList.length > 0 ?

                  <Grid item xs={12} sm={12}>
                    <Table dataSource={categoryList} columns={columns} scroll={{ x: 1 }} />
                  </Grid>
                  :
                  categoryLoading ? <Box sx={{ width: '100%' }}>
                    <LinearProgress color={'success'} />
                  </Box> : <Typography>Kayıt bulunamadı</Typography>
              }
            </Card>


          {/* </Grid> */}
        </Box>

      </Container>
    </BasePage>
  )
}

export default Organization
