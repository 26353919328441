import React, { useState } from 'react'

import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

function RichTextEditor({ value, onChange, placeHolder }) {
    // const [value, setValue] = useState('');
  return (
    <ReactQuill theme="snow" value={value} onChange={onChange} placeholder={placeHolder} />
  )
}

export default RichTextEditor