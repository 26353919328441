import { Box, Button, CardActionArea, CardActions, CardContent, CardMedia, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { statusSlice } from '../../app/reducers/status';
import { selectUser } from '../../app/reducers/user';
import ParameterComponent from '../../components/parameter-component';
import { ServiceHelper } from '../../utils/service-helper';
import BasePage from '../core/base-page';
import SubmitButton from '../../components/submit-button';
import { getSession } from '../../utils/auth-helper';
import { MuiTelInput } from 'mui-tel-input';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Space, Table, Button as AntButton, Card, Tabs, Typography as AntTypography, Modal } from 'antd';
import dayjs from 'dayjs';
import { WhatsAppOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ExcelExport from '../../utils/excel-export';
import Search from 'antd/es/input/Search';
import CardHeaderTitle from '../../components/card-header-title';
import { getGenderName } from '../../utils/event-helper';


function Guest() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const inputImageRef = useRef(null);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [file, setFile] = useState("");
  const [updateId, setUpdateId] = useState();
  const [image, setImage] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [imageList, setImageList] = useState([]);
  const [selectedFilterOrganzation, setFilterOrganization] = useState('');
  const [selectedOrganzation, setOrganization] = useState('');
  const [organizationList, setOrganizationList] = useState();
  const [organizationFilterList, setOrganizationFilterList] = useState();
  const [birthDate, setBirthDate] = useState(dayjs());
  const [guestList, setGuestList] = useState([]);
  const [guestFilteredList, setGuestFilteredList] = useState([]);
  const [guestLoading, setGuestLoading] = useState(false);
  const [selectedGender, setGender] = useState('');
  const [phoneNumber, setPhone] = useState('');
  const [selectedGuestInfo, setSelectedGuestInfo] = useState();
  const [inputs, setInputs] = useState({
    guestFirstName: '',
    guestLastName: '',
    provionce: ''
  });
  const [inputErrors, setInputErrors] = useState({
    guestFirstNameError: false,
    guestLastNameError: false,
    phoneError: false,
    birthDateError: false,
    provionceError: false
  })
  const [submitted, setSubmitted] = useState(false);
  const { guestFirstName, guestLastName, provionce } = inputs;
  const { phoneError, guestLastNameError, guestFirstNameError, birthDateError, provionceError } = inputErrors;

  useEffect(() => {
    // console.log('Effect');
    // getAll();
    getOrganizations();
    // if (response.success == false) {
    // 	console.log(response.error);
    // 	dispatch(statusSlice.actions.setStatus(response))
    // } else {
    // 	// const { from } = location.state || { from: { pathname: "/" } };
    // 	// dispatch(userSlice.actions.login(response.data));
    // 	// Navigate(from.pathname);
    // }



  }, []);

  // async function getAll() {
  //   setCategoryLoading(true);
  //   const response = await ServiceHelper.get('/guest/getAll');
  //   // dispatch(statusSlice.actions.setStatus(response))
  //   // console.log(response);
  //   setImageList(response.data.result);
  //   setCategoryLoading(false);
  // }


  async function getAllGuests(organizationId) {
    setGuestLoading(true);
    const response = await ServiceHelper.get(`/guest/getAll/${organizationId}`);
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      // dispatch(statusSlice.actions.setStatus(response))
      const l = response.data.result.map(p => ({ ...p, key: p.id }));
      setGuestList(l);
      setGuestFilteredList(l);
      setGuestLoading(false);
    }
  }

  async function getOrganizations() {
    const { id } = await getSession();
    const response = await ServiceHelper.get(`/organization/getAll/${id}`);
    // dispatch(statusSlice.actions.setStatus(response))
    // console.log(response);
    setOrganizationList(Object.assign([], response.data.result));
    setOrganizationFilterList(Object.assign([], response.data.result));
  }
  const handlePhoneChange = (newPhone) => {
    // console.log('Phone: ', phoneNumber, newPhone);
    // newPhone.length <= 13 ?
    setPhone(newPhone) //:
    // setPhone(phoneNumber);
  }
  const convertToBlob = (e) => {

    setImagePath(e.target.files[0]);
    // let image = URL?.createObjectURL(e.target.files[0]);
    // setFile(image);

    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      // console.log(reader.result);
      setImage(reader.result);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputValues => ({ ...inputValues, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setInputErrors({
      nameError: false,
      descriptionError: false
    })
    if (guestFirstName == "") {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, guestFirstNameError: true }))
    }
    if (guestLastName == "") {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, guestLastNameError: true }))
    }

    if (guestFirstName && guestLastName && phoneNumber && selectedOrganzation) {

      setSubmitted(true);
      // const location = { type: 'Point', coordinates: [coordinateX, coordinateY] };

      const formData = {
        organizationId: selectedOrganzation,
        firstName: guestFirstName,
        lastName: guestLastName,
        phoneNumber: normalizePhoneNumber(),
        birthDate: birthDate,
        provionce: provionce,
        gender: selectedGender
      }
      // const formData = new FormData();
      // formData.append('organizationId', selectedOrganzation);
      // formData.append('firstName', guestFirstName);
      // formData.append('lastName', guestLastName);
      // formData.append('phoneNumber', phoneNumber);
      // formData.append('birthDate', birthDate);


      if (updateId) {
        formData.id = updateId;
        let response = await ServiceHelper.put('/guest/update', formData);
        if (response.success == false) {
          console.log(response.error);
          dispatch(statusSlice.actions.setStatus(response))
        } else {
          dispatch(statusSlice.actions.setStatus({ message: 'Misafir kaydı güncellendi', type: 'success', status: true }))
          await getAllGuests(selectedFilterOrganzation);
          // const { from } = location.state || { from: { pathname: "/" } };
          // dispatch(userSlice.actions.login(response.data));
          // Navigate(from.pathname);
        }

      }
      else {
        let response = await ServiceHelper.post('/guest/save', formData)
        if (response.success == false) {
          console.log(response.error);
          dispatch(statusSlice.actions.setStatus(response))
        } else {
          // await getAll();
          dispatch(statusSlice.actions.setStatus({ message: 'Misafir kaydı yapıldı', type: 'success', status: true }))
          await getAllGuests(selectedFilterOrganzation);
        }
      }
      clearData();
    }
  }

  const clearData = () => {
    setUpdateId(null);
    setInputs({
      guestFirstName: "",
      guestLastName: "",
      provionce: ""
    });
    setPhone("");
    setBirthDate(dayjs(new Date()));
    setSubmitted(false);
    setGender('');
  }

  const handleDelete = async (id) => {
    // console.log('ID: ', id);
    let response = await ServiceHelper.delete(`/guest/deleteById/${id}`)
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      await getAllGuests(selectedFilterOrganzation);
      dispatch(statusSlice.actions.setStatus(response))
      // await getAll();
    }
  }

  const handleUpdate = async (id) => {
    // console.log('ID: ', id);
    setUpdateId(id);
    let response = await ServiceHelper.get(`/guest/get/${id}`)
    if (response.success == false) {
      console.log(response.error);
      dispatch(statusSlice.actions.setStatus(response))
    } else {
      const { firstName, lastName, phoneNumber, provionce, birthDate, organizationId, gender } = response.data.result;
      setOrganization(organizationId)
      setGender(gender)
      setInputs({ guestFirstName: firstName, guestLastName: lastName, provionce });
      setPhone(formatPhoneNumber(phoneNumber))
      setBirthDate(dayjs(birthDate))
      window.scrollTo(0, 0);
    }
  }


  const handleCategoriChange = (event) => {
    setOrganization(event.target.value);
  };

  const handleOrganizationFilterChange = (event) => {
    setFilterOrganization(event.target.value);
    getAllGuests(event.target.value)
  };

  function formatPhoneNumber(phone) {
    let formattedPhone = `+90 ${phone}`
    return formattedPhone;
  }

  function normalizePhoneNumber() {
    let normalizePhone = phoneNumber.replace('+90', '').replaceAll(' ', '');
    return normalizePhone;
  }

  const onSearch = (v) => {
    const value = v.toLowerCase();
    const result = guestList.filter(p =>
      p.firstName.toLowerCase().includes(value) ||
      p.lastName.toLowerCase().includes(value) ||
      p.phoneNumber.toLowerCase().includes(value) ||
      p.provionce.toLowerCase().includes(value))
    setGuestFilteredList(result);
  }

  const onSearchChange = (e) => {
    onSearch(e.target.value)
  }

  const columns = [
    {
      title: 'Katılımcı Adı',
      key: 'name',
      width: 150,
      render: (_, record) => (
        <Stack direction={'column'}>
          <Space>
            <AntButton type='button' icon={<InfoCircleOutlined />} onClick={() => {
              showModal(record);
            }}>
            </AntButton>
            <AntTypography>{record.firstName}</AntTypography>
          </Space>
        </Stack>
      )
    },
    // {
    //   title: 'Adı',
    //   dataIndex: 'firstName',
    //   key: 'firstName',
    //   width: 100
    // },
    {
      title: 'Soyadı',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 100
    },
    {
      title: 'Telefon',
      key: 'phoneNumber',
      width: 170,
      // dataIndex: 'phoneNumber',
      render: (_, record) => (
        <Space size="middle" >
          <AntButton icon={<WhatsAppOutlined />} onClick={() => {
            // const encodedURL = encodeURIComponent(ServiceHelper.getUrl(`guest/profile/${record.guidId}`));
            const encodedURL = encodeURIComponent(ServiceHelper.getUrl(`guest/profile/${record.guidId}`));
            let url =
              `whatsapp://send?text=Kıymetli Katılımcımız,%0a%0aBayramlaşma girişinde kullanacağınız QR KOD’a aşağıdaki linkten ulaşabilirsiniz.%0a%0a${encodedURL}&phone=90${record.phoneNumber}`
            window.open(url);



            // let url = `https://web.whatsapp.com/send?phone=90${record.phoneNumber}`;
            // url += `&text=${encodeURI(ServiceHelper.getUrl(`guest/profile/${record.guidId}`))}&app_absent=0`;
            // window.open(url);
          }}>{record.phoneNumber}
          </AntButton>
          {/* <a target='_blank' href={}>Whatsapp</a> */}
        </Space>
      )
    },
    {
      title: 'İl',
      dataIndex: 'provionce',
      key: 'provionce',
      width: 100,
    },
    {
      title: 'Aksiyon',
      key: 'action',
      width: 230,
      fixed: 'right',
      render: (_, record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a>
          <a>Delete</a> */}
          <AntButton type="primary" onClick={() => { handleUpdate(record.id) }}>Düzenle</AntButton>
          <AntButton type="primary" danger onClick={() => { handleDelete(record.id) }}>Sil</AntButton>
        </Space>
      ),
    }
  ]


  const showModal = (guest) => {
    setSelectedGuestInfo(guest)
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    handleUpdate(selectedGuestInfo.id);
    setIsModalOpen(false);
    setActiveTabKey('1')

  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <BasePage maxWidth={'md'}>
      <Container maxWidth="md" sx={{ mt: 2 }}>
        <Card bordered={false}>
          <CardHeaderTitle title={'Katılımcılar'} />
          <Tabs
            style={{ margin: -24 }}
            // tabBarStyle={{ marginLeft: 12 }}
            centered
            activeKey={activeTabKey}
            // tabBarExtraContent='top'
            onChange={(e) => {setActiveTabKey(e) }}
            // type="card"
            items={[
              {
                label: `Katılımcı Tanımla`,
                key: "1",
                children: <Card bordered={false}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Organizasyon</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedOrganzation == null ? 0 : selectedOrganzation}
                          defaultValue={''}
                          label="Organizasyon"
                          onChange={handleCategoriChange}
                        >
                          {
                            organizationList?.map((item, key) => {
                              return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>;
                            })
                          }
                        </Select>
                      </FormControl>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="guestFirstName"
                            label="Adı"
                            name="guestFirstName"
                            autoComplete="guestFirstName"
                            // autoFocus
                            onChange={handleChange}
                            value={guestFirstName}
                            error={guestFirstNameError}
                            helperText={guestFirstNameError && 'Zorunlu Alan'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="guestLastName"
                            label="Soyadı"
                            name="guestLastName"
                            autoComplete="guestLastName"
                            // autoFocus
                            onChange={handleChange}
                            value={guestLastName}
                            error={guestLastNameError}
                            helperText={guestLastNameError && 'Zorunlu Alan'}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                          <MuiTelInput fullWidth required
                            id="phoneNumber"
                            label="Telefon Numarası"
                            autoComplete="off"
                            error={phoneError}
                            defaultCountry='TR'
                            forceCallingCode
                            onlyCountries={['TR']}
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                            helperText={phoneError && 'Telefon numarası giriniz'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DatePicker
                            value={birthDate}
                            margin="normal"
                            timezone='UTC'
                            defaultValue={dayjs()}
                            onChange={(date, t, x) => {
                              console.log('Date: ', date);
                              console.log('T: ', t);
                              console.log(x);
                              setBirthDate(dayjs(date))
                            }}
                            sx={{ width: '-webkit-fill-available', mt: 2, mb: 2 }}
                            name="birthDate"
                            label="Doğum Tarihi"
                            type="birthDate"
                            id="birthDate"
                            autoComplete="off"
                            format={'DD.MM.YYYY'}
                            views={['day', 'month', 'year']}
                            error={birthDateError}
                            helperText={birthDateError && 'Doğum Tarihi Giriniz'}
                            ampm={"false"} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            sx={{ mt: 0 }}
                            margin="dense"
                            // required
                            fullWidth
                            id="provionce"
                            label="İl"
                            name="provionce"
                            autoComplete="provionce"
                            // autoFocus
                            onChange={handleChange}
                            value={provionce}
                          // error={provionceError}
                          // helperText={provionceError && 'Zorunlu Alan'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <ParameterComponent code={'Gender'} title={'Cinsiyet'} parameterValue={selectedGender} onChange={(value) => { setGender(value) }} />
                        </Grid>
                      </Grid>

                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SubmitButton submitted={submitted} title={'KAYDET'} iconName={'Save'} variant={'outlined'} onClick={handleSubmit} />
                      <Button variant="outlined" sx={{ ml: 1 }} onClick={clearData} >TEMİZLE</Button>
                    </Grid>
                  </Grid>
                </Card>,
              },
              {
                label: `Katılımcı Listesi`,
                key: "2",
                children: <Box >

                  <Card bordered={false} >
                    {/* <CardHeaderTitle title={'Katılımcı Listesi'} /> */}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Organizasyon</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedFilterOrganzation == null ? 0 : selectedFilterOrganzation}
                        defaultValue={''}
                        label="Organizasyon"
                        onChange={handleOrganizationFilterChange}
                      >
                        {
                          organizationFilterList?.map((item, key) => {
                            return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>;
                          })
                        }
                      </Select>
                    </FormControl>
                    <Stack direction={'row'} sx={{ mt: 1 }}>
                      <Search
                        placeholder="Arama..."
                        allowClear
                        // enterButton="Ara"
                        size="large"
                        onChange={onSearchChange}
                        onSearch={onSearch}
                      />
                      {guestFilteredList?.length > 0 && <ExcelExport excelData={guestList} fileName={"Katılımcı_Listesi"} />}
                    </Stack>
                    <Box>
                      {
                        guestFilteredList && guestFilteredList.length > 0 ?
                          <Box sx={{ ml: '-24px', mr: '-24px', mt: '12px' }}>
                            <Table style={{ margin: '-24ox' }} scroll={{ x: 1 }} dataSource={guestFilteredList} columns={columns} />
                          </Box>
                          :
                          guestLoading ? <Box sx={{ width: '100%' }}>
                            <LinearProgress color={'success'} />
                          </Box> : <Typography>Kayıt bulunamadı</Typography>
                      }
                    </Box>
                  </Card>
                </Box>,
              }
            ]
            }
          />
          {/* <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Organizasyon</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOrganzation == null ? 0 : selectedOrganzation}
                  defaultValue={''}
                  label="Organizasyon"
                  onChange={handleCategoriChange}
                >
                  {
                    organizationList?.map((item, key) => {
                      return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>;
                    })
                  }
                </Select>
              </FormControl>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="guestFirstName"
                    label="Adı"
                    name="guestFirstName"
                    autoComplete="guestFirstName"
                    // autoFocus
                    onChange={handleChange}
                    value={guestFirstName}
                    error={guestFirstNameError}
                    helperText={guestFirstNameError && 'Zorunlu Alan'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="guestLastName"
                    label="Soyadı"
                    name="guestLastName"
                    autoComplete="guestLastName"
                    // autoFocus
                    onChange={handleChange}
                    value={guestLastName}
                    error={guestLastNameError}
                    helperText={guestLastNameError && 'Zorunlu Alan'}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <MuiTelInput fullWidth required
                    id="phoneNumber"
                    label="Telefon Numarası"
                    autoComplete="off"
                    error={phoneError}
                    defaultCountry='TR'
                    forceCallingCode
                    onlyCountries={['TR']}
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    helperText={phoneError && 'Telefon numarası giriniz'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    value={birthDate}
                    margin="normal"
                    timezone='UTC'
                    defaultValue={dayjs()}
                    onChange={(date, t, x) => {
                      console.log('Date: ', date);
                      console.log('T: ', t);
                      console.log(x);
                      setBirthDate(date)
                    }}
                    sx={{ width: '-webkit-fill-available', mt: 2, mb: 2 }}
                    name="birthDate"
                    label="Doğum Tarihi"
                    type="birthDate"
                    id="birthDate"
                    autoComplete="off"
                    format={'DD.MM.YYYY'}
                    views={['day', 'month', 'year']}
                    error={birthDateError}
                    helperText={birthDateError && 'Doğum Tarihi Giriniz'}
                    ampm={"false"} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ mt: 0 }}
                    margin="dense"
                    // required
                    fullWidth
                    id="provionce"
                    label="İl"
                    name="provionce"
                    autoComplete="provionce"
                    // autoFocus
                    onChange={handleChange}
                    value={provionce}
                  // error={provionceError}
                  // helperText={provionceError && 'Zorunlu Alan'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ParameterComponent code={'Gender'} title={'Cinsiyet'} parameterValue={selectedGender} onChange={(value) => { setGender(value) }} />
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12} sm={12}>
              <SubmitButton submitted={submitted} title={'KAYDET'} iconName={'Save'} variant={'outlined'} onClick={handleSubmit} />
              <Button variant="outlined" sx={{ ml: 1 }} onClick={clearData} >TEMİZLE</Button>
            </Grid>
          </Grid> */}

        </Card>
        {/* 
        <Box sx={{ mt: 2 }} >

          <Card bordered={false} >
            <CardHeaderTitle title={'Katılımcı Listesi'} />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Organizasyon</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedFilterOrganzation == null ? 0 : selectedFilterOrganzation}
                defaultValue={''}
                label="Organizasyon"
                onChange={handleOrganizationFilterChange}
              >
                {
                  organizationFilterList?.map((item, key) => {
                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>;
                  })
                }
              </Select>
            </FormControl>
            <Stack direction={'row'} sx={{ mt: 1 }}>
              <Search
                placeholder="Arama..."
                allowClear
                // enterButton="Ara"
                size="large"
                onChange={onSearchChange}
                onSearch={onSearch}
              />
              {guestFilteredList?.length > 0 && <ExcelExport excelData={guestList} fileName={"Katılımcı_Listesi"} />}
            </Stack>
            <Box>
              {
                guestFilteredList && guestFilteredList.length > 0 ?
                  <Box sx={{ ml: '-24px', mr: '-24px', mt: '12px' }}>
                    <Table style={{ margin: '-24ox' }} scroll={{ x: 1 }} dataSource={guestFilteredList} columns={columns} />
                  </Box>
                  :
                  guestLoading ? <Box sx={{ width: '100%' }}>
                    <LinearProgress color={'success'} />
                  </Box> : <Typography>Kayıt bulunamadı</Typography>
              }
            </Box>
          </Card>
        </Box> */}
        <Modal title="Katılımcı Bilgileri" open={isModalOpen} onOk={handleOk} okText={'Düzenle'} onCancel={handleCancel} cancelText={'İptal'}>
          <p style={{ margin: 0, padding: 0 }}><span>Adı: </span>{selectedGuestInfo?.firstName}</p>
          <p style={{ margin: 0, padding: 0 }}><span>Soyadı: </span>{selectedGuestInfo?.lastName}</p>
          <p style={{ margin: 0, padding: 0 }}><span>Telefon: </span>{selectedGuestInfo?.phoneNumber}</p>
          <p style={{ margin: 0, padding: 0 }}><span>İl: </span>{selectedGuestInfo?.provionce}</p>
          <p style={{ margin: 0, padding: 0 }}><span>Doğum Tarihi: </span>{dayjs(selectedGuestInfo?.birthDate).locale('tr').format('DD.MM.YYYY')}</p>
          <p style={{ margin: 0, padding: 0 }}><span>Cinsiyet: </span>{getGenderName(selectedGuestInfo?.gender)}</p>
          <p style={{ margin: 0, padding: 0 }}><span>QR Linki: </span>
            <a href={ServiceHelper.getUrl(`guest/profile/${selectedGuestInfo?.guidId}`)} target='_blank'>
              {ServiceHelper.getUrl(`guest/profile/${selectedGuestInfo?.guidId}`)}
            </a>
          </p>
        </Modal>
      </Container>
    </BasePage >
  )
}

export default Guest
