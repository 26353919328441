import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Copyright from '../../components/copyright';
import { Card, CardContent, Snackbar, SpeedDial, Stack } from '@mui/material';
import { selectUser } from '../../app/reducers/user';
import { getStatus, statusSlice } from '../../app/reducers/status';
import Logo from '../../assets/images/mec.webp';
import { indigo, lightBlue } from '@mui/material/colors';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { WhatsAppOutlined } from '@ant-design/icons';
import { getGuestSession } from '../../utils/auth-helper';
import { Button } from 'antd';
import { useTheme } from '@emotion/react';

function BasePage(props) {
  const { maxWidth } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const status = useSelector(getStatus);
  const [guestInfo, setGuestInfo] = useState();

  useEffect(() => {
    getGuest();
  }, []);

  // const error = useSelector(getError);
  const headerBGColor = '#093f53'
  const baseStyle = {
    display: 'flex',
    justifyContent: 'center'
  }

  const handleCloseMessage = () => {
    dispatch(statusSlice.actions.setStatus({ success: true, result: {}, message: '' }))
  }

  const boxSX = !user.logginIn ?
    {
      bgcolor: '#e0f1e0',
      // bgcolor: '#e5cb7d',
      // pt: 8,
      // p: 2,
      mt: 1,
      mr: 1,
      ml: 1,
      mb: 8,
      boxShadow: 6,
      borderRadius: 4,
      // ...baseStyle
    }
    :
    {
      // bgcolor: '#e5cb7d',
      bgcolor: '#e0f1e0',
      // pt: 8,
      p: 2,
      mt: 12,
      mr: 2,
      ml: 2,
      mb: 2,
      boxShadow: 6,
      borderRadius: 4,
      // ...baseStyle
    };

  // console.log('Status:', status);

  const getGuest = async () => {
    const gusetInfo = await getGuestSession();
    setGuestInfo(gusetInfo)
  }


  return (
    <main style={{
      // display: 'flex', justifyContent: 'center', 
      // backgroundColor: '#4e4b45' 
      backgroundColor: '#f5f5f5'
    }}
    >
      {/* {
        !user.logginIn ?
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 1,
            pb: 1,
            mb: 1,
            bgcolor: headerBGColor,
            boxShadow: 3,
            height: 64,
          }}>
            <img src={Logo} alt='Logo' />
          </Box>
          :
          <Box sx={{
            // display: 'flex',
            // justifyContent: 'center',
            pt: 1,
            pb: 1,
            mb: 1,
            // bgcolor: headerBGColor,
            // boxShadow: 3,
            height: 64,
          }}>
          </Box>
      } */}

      {/* Hero unit */}
      <Box
        sx={{ pt: 8 }}
        direction="column"
        alignItems="center"
        justifyContent="center">

        {
          status.status &&
          <Snackbar open={status.status} autoHideDuration={3000} onClose={handleCloseMessage}>
            <Alert variant='filled' elevation={10} severity={status.type} onClose={handleCloseMessage}>{`${status.message}`}</Alert>
          </Snackbar>

        }
        <Box maxWidth={maxWidth}
          sx={{
            minWidth: '-webkit-fill-available'
          }}
        // sx={boxSX}
        >
          {/* <Card>
          <CardContent> */}
          {props.children}
          {/* </CardContent>
        </Card> */}
          {/* <Copyright sx={{ mt: 8, mb: 8, p: 4 }} /> */}
          {/* <SpeedDial
            ariaLabel="Whatsapp Destek"
            color='success'
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            onClick={() => {
              let url = `whatsapp://send?text=${encodeURIComponent("Selamunaleyküm,\nBir desteğe ihtiyacım var.")}&phone=905076965486`
              window.open(url);
            }}
            icon={<WhatsAppIcon />}
          ></SpeedDial> */}
        </Box>

        <Container maxWidth="xs">
          <Stack sx={{ mt: 4, mb: 4 }} display={'flex'} justifyContent={'center'}>
            {/* <Button style={{ backgroundColor: '#0f8568', color: '#ffffff' }} shape="default" size={"large"} icon={<WhatsAppOutlined />} onClick={() => {
              let url = `whatsapp://send?text=${encodeURIComponent("Selamunaleyküm,\nBir desteğe ihtiyacım var.")}&phone=905334732970`
              window.open(url);
            }}>WhatsApp Canlı Destek</Button> */}
          </Stack>
        </Container>
      </Box>

      {/* {status.status && <Container maxWidth="xs"><Alert severity="info">{status.message}</Alert></Container>} */}

    </main>
  )
}

export default BasePage
