import { Grid, IconButton, Stack, Typography } from '@mui/material'
import LeftNavigationIcon from '@mui/icons-material/ArrowBackIosNew';
import ReactCountryFlag from 'react-country-flag';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import HeaderTitle from './header-title';
import { blueGrey, lightBlue } from '@mui/material/colors';
import { Divider } from 'antd';

function TopNavigation({ showNavigation = true, title }) {
  const navigate = useNavigate();
  return (
    <Grid item //sx={{ mt: 1 }} 
    xs={12}>
      <Stack sx={{ mt: '4px', ml: '4px'}} direction={'row'} >
        {showNavigation &&
          <IconButton sx={{}} aria-label="left-icon" size="large" color='success' onClick={() => { navigate(-1) }}>
            <LeftNavigationIcon sx={{ color: blueGrey[700] }} />
          </IconButton>}
        <Stack direction={'row'} sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          {/* <Typography sx={{  color: 'white' }}>{title}</Typography> */}
          <Stack sx={{ display: 'flex', justifyContent: 'center' }}>
            <HeaderTitle title={title} />
          </Stack>
          {/* <IconButton aria-label="delete" size="medium" color='success'>
            <ReactCountryFlag countryCode="TR" svg />
          </IconButton>
          <IconButton aria-label="delete" size="medium" color='success'>
            <ReactCountryFlag countryCode="US" svg />
          </IconButton>
          <IconButton aria-label="delete" size="medium" color='success'>
            <ReactCountryFlag countryCode="SA" svg />
          </IconButton> */}
        </Stack>
      </Stack>
        <Divider style={{ marginTop: 4 }}/>
    </Grid>
  )
}

export default TopNavigation