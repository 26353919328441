import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MuiTelInput } from 'mui-tel-input';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { statusSlice } from '../../app/reducers/status';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SubmitButton from '../../components/submit-button';
import { ServiceHelper } from '../../utils/service-helper';
import BasePage from '../core/base-page';
import { guestSlice } from '../../app/reducers/guest';
import dayjs from 'dayjs';
import { getGuestSessionStatus } from '../../utils/auth-helper';
import { Button, Card } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import { Stack } from '@mui/material';


function GuestLogin() {
  // const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [phoneNumber, setPhone] = useState('');
  const [birthDate, setBirthDate] = useState(dayjs());

  // const [inputs, setInputs] = useState({
  //   birthDate: ''
  // });
  const [inputErrors, setInputErrors] = useState({
    phoneError: false,
    birthDateError: true
  })
  const [submitted, setSubmitted] = useState(false);
  // const { birthDate } = inputs;
  const { phoneError, birthDateError } = inputErrors;

  // reset login status
  useEffect(() => {
    getGuestInfo();

  }, []);

  async function getGuestInfo() {
    const isActiveSession = await getGuestSessionStatus();
    if (isActiveSession) {
      navigate('/');
    }
    // const userInfo = await ServiceHelper.get(`/users/get/${id}`);
    // const { firstName, lastName, email } = userInfo.data.result;
    // dispatch(userSlice.actions.setUserInfo({ data: { id, firstName, lastName, email } }))
  }

  const handlePhoneChange = (newPhone) => {
    // console.log('Phone: ', phoneNumber, newPhone);
    // newPhone.length <= 13 ?
    setPhone(newPhone) //:
    // setPhone(phoneNumber);
  }
  function handleChange(e) {
    const { name, value } = e.target;
    // setInputs(inputValues => ({ ...inputValues, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    setInputErrors({
      phoneError: false,
      birthDateError: false
    })
    if (phoneNumber == "") {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, phoneError: true }))
    }
    if (birthDate == "" || birthDate == undefined) {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, birthDateError: true }))
    }

    if (phoneNumber && birthDate) {
      const date = dayjs.tz(birthDate);
      console.log(date);
      // get return url from location state or default to home page
      let response = await ServiceHelper.post('/guest/signin', { phoneNumber: normalizePhoneNumber(phoneNumber), birthDate })
      if (response.success === false) {
        console.log(response.error);
        dispatch(statusSlice.actions.setStatus({ ...response, type: 'error', status: true }))
      } else {
        const { from } = location.state || { from: { pathname: "/guest/dashboard" } };
        dispatch(guestSlice.actions.login(response.data));
        dispatch(statusSlice.actions.setStatus({ message: 'Misafir girişi yapıldı', type: 'success', status: true }))
        navigate(from.pathname);
      }
    }
    setSubmitted(false);
  }

  function normalizePhoneNumber() {
    let normalizePhone = phoneNumber.replace('+90', '').replaceAll(' ', '');
    return normalizePhone;
  }

  return (
    <BasePage maxWidth={'sm'}>
      <Container component="main" maxWidth="xs" sx={{ mt: 2 }}>
        <Card bordered={false}>
          <Box
            sx={{
              mt: 2,
              mb: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 2, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
              Giriş Yap
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <MuiTelInput fullWidth required
                id="phoneNumber"
                label="Telefon Numarası"
                autoFocus
                autoComplete="off"
                error={phoneError}
                defaultCountry='TR'
                // disableDropdown
                forceCallingCode
                onlyCountries={['TR']}
                value={phoneNumber}
                onChange={handlePhoneChange}
                helperText={phoneError && 'Telefon numarası giriniz'}
              />
              {/* <TextField
              margin="normal"
              required
              fullWidth
              name="birthDate"
              label="Doğum Tarihi"
              type="birthDate"
              id="birthDate"
              autoComplete="current-password"
              onChange={handleChange}
              error={birthDateError}
              helperText={birthDateError && 'Doğum Tarihi Giriniz'}
            /> */}
              <DatePicker
                value={birthDate}
                margin="normal"
                timezone='UTC'
                defaultValue={dayjs()}
                onChange={(date, t, x) => {
                  // console.log('Date: ', date);
                  // console.log('T: ', t);
                  // console.log(x);
                  setBirthDate(date)
                }}
                sx={{ width: '-webkit-fill-available', mt: 2, mb: 2 }}
                name="birthDate"
                label="Doğum Tarihi"
                type="birthDate"
                id="birthDate"
                autoComplete="off"
                views={['year', 'month', 'day']}
                format={'DD.MM.YYYY'}
                error={birthDateError}
                helperText={birthDateError && 'Doğum Tarihi Giriniz'}
                slotProps={{
                  textField: {
                    helperText: 'Doğum tarihinizi gün/ay/yıl şeklinde giriniz',
                  },
                }}
                ampm={false} />
              <SubmitButton submitted={submitted} iconName={"Login"} variant={'contained'} fullWidth title={"GİRİŞ"} />

            </Box>
          </Box>
        {/* <Stack sx={{ mt: 4 }} display={'flex'} justifyContent={'center'}>
          <Button style={{ color: '#ff4c4c' }} type="link" shape="round" size={"middle"} icon={<WhatsAppOutlined />} onClick={() => {
            let url = `whatsapp://send?text=${encodeURIComponent("Selamunaleyküm,\nBir desteğe ihtiyacım var.")}&phone=905334732970`
            window.open(url);
          }}>WhatsApp Canlı Destek</Button>
        </Stack> */}
        </Card>
{/* 
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="link" shape="round" icon={<WhatsAppIcon />} size={"small"} onClick={() => { navigate('/admin/login') }}>Yönetici Girişi</Button>
          </div> */}
      </Container>
    </BasePage>
  )
}

export default GuestLogin
