import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/user';
import statusReducer from './reducers/status';
import guestReducer from './reducers/guest';

export const store = configureStore({
  reducer: {
    user: userReducer,
    status: statusReducer,
    guest: guestReducer,
  },
});
