function getSessionStatus() {
  let token = localStorage.getItem('token');
  if (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const session = JSON.parse(window.atob(base64))
    return session.exp > Date.now() / 1000;
  }
  else {
    return false
  }
}

function getGuestSessionStatus() {
  let token = localStorage.getItem('guest-token');
  if (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const session = JSON.parse(window.atob(base64))
    return session.exp > Date.now() / 1000;
  }
  else {
    return false
  }
}

function getSession() {
  let token = localStorage.getItem('token');
  if (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const parsedValue = JSON.parse(window.atob(base64))
    return parsedValue;
  } else {
   return {}; 
  }
}

function getGuestSession() {
  let token = localStorage.getItem('guest-token');
  if (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const parsedValue = JSON.parse(window.atob(base64))
    return parsedValue;
  } else {
   return {}; 
  }
}

export {
  getSessionStatus,
  getSession,
  getGuestSession,
  getGuestSessionStatus
}