import React from 'react'
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import { Button } from 'antd';
import { FileExcelFilled } from '@ant-design/icons';
import { ServiceHelper } from './service-helper';


const exportToExcel = async ({ excelData, fileName }) => {
  const fileType = 'application/vnd.openxmIformats-officedocument . spreadsheetmI. sheet; charset-UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(excelData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}


function ExcelExport({ excelData, fileName }) {


  const exportToExcelInner = () => {
    const filteredExcelData = filterExcelData();
    exportToExcel({ excelData: filteredExcelData, fileName });
  }

  const filterExcelData = () => {
    const result = excelData.map(item => ({
      Adı: item.firstName,
      Soyadı: item.lastName,
      Telefon: item.phoneNumber,
      Şehir: item.provionce,
      'Profil Linki': ServiceHelper.getUrl(`guest/profile/${item.guidId}`)
    })
    );

    return result;
  }

  return (
    <Button style={{ marginLeft: 4 }} size='large' icon={<FileExcelFilled />} onClick={() => { exportToExcelInner() }}>Excel'e Aktar</Button>
  )
}

export default ExcelExport

export { exportToExcel }