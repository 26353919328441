import { Grid, IconButton, Stack, Typography } from '@mui/material'
import LeftNavigationIcon from '@mui/icons-material/ArrowBackIosNew';
import ReactCountryFlag from 'react-country-flag';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import HeaderTitle from './header-title';
import { blueGrey, lightBlue } from '@mui/material/colors';
import { Divider } from 'antd';

function CardHeaderTitle({ title, disableMargin }) {
  const margin = disableMargin ? {mt: '12px'} : { ml: '-24px', mr: '-24px', mt: '-12px' } ;
  return (
    <Grid item sx={margin}
      xs={12}>
      <Stack direction={'row'} >
        <Stack direction={'row'} sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          {/* <Typography sx={{  color: 'white' }}>{title}</Typography> */}
          <Stack sx={{ display: 'flex', justifyContent: 'center' }}>
            <HeaderTitle title={title} />
          </Stack>
          {/* <IconButton aria-label="delete" size="medium" color='success'>
            <ReactCountryFlag countryCode="TR" svg />
          </IconButton>
          <IconButton aria-label="delete" size="medium" color='success'>
            <ReactCountryFlag countryCode="US" svg />
          </IconButton>
          <IconButton aria-label="delete" size="medium" color='success'>
            <ReactCountryFlag countryCode="SA" svg />
          </IconButton> */}
        </Stack>
      </Stack>
      <Divider style={{ marginTop: '12px' }} />
    </Grid>
  )
}

export default CardHeaderTitle