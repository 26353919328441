import { useEffect, useState } from 'react'
import BasePage from '../core/base-page'
import { Container } from '@mui/material'
import TopNavigation from '../../components/top-navigation'
import { Button, Card, Collapse, Divider, Space, Table } from 'antd'
import { getGuestSession } from '../../utils/auth-helper'
import { ServiceHelper } from '../../utils/service-helper'
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'

function Information() {
  const [announcementList, setAnnouncementList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getEventList();
  }, []);

  const getEventList = async () => {
    const { organizationId, id, gender } = await getGuestSession();
    const response = await ServiceHelper.get(`/announcement/getAllAnnouncements/${organizationId}/${gender}`);
    console.log(response);
    if (response.success) {
      // const { guest, events, eventAttendance } = response.data.result;
      // const guestEvent = await getGuestAttendance(id);
      // setGuestEventList(guestEvent);


      setAnnouncementList(response.data.result);

      // dispatch(statusSlice.actions.setStatus({ ...response, status: true }))      

    } else {

    }
  }

  const columns = [
    {
      title: 'Duyuru',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Detaylar',
      dataIndex: 'detay',
      render: (_, record) => (
        <Space size="middle" >
          <Button type='link' icon={<InfoCircleOutlined />} onClick={() => {
            navigate('/guest/event-detail', { state: { eventId: record.id } });
          }}>Detaylar
          </Button>
        </Space>
      )
    }
  ];


  return (
    <BasePage maxWidth={'md'}>
      <Container maxWidth="md" sx={{}} style={{ padding: 8, justifyContent: 'center' }}>
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <TopNavigation title={"Duyurular"} />

          <Space style={{ padding: '0px 16px 16px 16px', display: 'block' }}>

            <Collapse
              size="large"
              items={announcementList.map(p => { return { key: p.id, label: p.name, children: <div dangerouslySetInnerHTML={{ __html: p.description }} /> } })} />
          </Space>

          {/* < Table scroll = {{ x: 1 }} dataSource={announcementList} columns={columns} /> */}
        </Card>
      </Container>
    </BasePage>
  )
}

export default Information