import React, { useEffect, useState } from 'react'
import BasePage from '../core/base-page'
import { Container, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import TopNavigation from '../../components/top-navigation'
import { Button, Card, Divider, Space, Table } from 'antd'
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom'
import { ServiceHelper } from '../../utils/service-helper'
import { WhatsAppOutlined } from '@ant-design/icons';
import { getGuestSession, getSession } from '../../utils/auth-helper'

function Attendee() {

  const [guestList, setGuestList] = useState([]);
  const [organizationList, setOrganizationList] = useState();
  const [selectedOrganzation, setOrganization] = useState('');
  const [gusetInfo, setGuestInfo] = useState();

  useEffect(() => {
    getOrganizations();
    getOrganizationAttendees();
  }, []);

  async function getOrganizations() {
    const { id } = await getSession();
    const response = await ServiceHelper.get(`/organization/getAll/${id}`);
    // dispatch(statusSlice.actions.setStatus(response))
    // console.log(response);
    setOrganizationList(response.data.result);
  }
  const getOrganizationAttendees = async (organizationId) => {
    // const organizationId = 5, id = 1;
    const response = await ServiceHelper.get(`/organizationattendancelog/getAll/${organizationId}`);
    console.log(response);
    if (response.success) {
      // const { guest, events, eventAttendance } = response.data.result;
      setGuestList(response.data.result)
      // dispatch(statusSlice.actions.setStatus({ ...response, status: true }))      

    } else {

    }
  }

  const handleOrganizationChange = (event) => {
    setOrganization(event.target.value);
    console.log("Selected org", event.target.value);
    getOrganizationAttendees(event.target.value);
  };

  async function getGuestInfo() {
    // const { guidId } = await getGuestSession();
    // const response = await ServiceHelper.get(`/guest/getByGuid/${guidId}`);
    // const { firstName, lastName, provionce } = response.data.result;
    return { firstName: "Server Yaşam", lastName: "Vakfı", provionce: "İstanbul" };
  }


  const columns = [
    {
      title: 'Adı',
      // dataIndex: 'Guest.firstName',
      key: 'firstName',
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          {record.Guest.firstName}
        </Space>
      )
    },
    {
      title: 'Soyadı',
      render: (_, record) => (
        <Space size="middle">
          {record.Guest.lastName}
        </Space>
      ),
      key: 'lastName',
      width: 100
    },
    {
      title: 'Telefon',
      key: 'phoneNumber',
      width: 170,
      // dataIndex: 'phoneNumber',
      render: (_, record) => (
        <Space size="middle">
          {record.Guest.phoneNumber}
        </Space>
      )
    },
    {
      title: 'İl',
      render: (_, record) => (
        <Space size="middle">
          {record.Guest.provionce}
        </Space>
      ),
      key: 'provionce',
      width: 100,
    }

  ]
  console.log("List", guestList);
  return (
    <BasePage maxWidth={'md'}>
      <Container maxWidth="md" sx={{}} style={{ padding: 8, justifyContent: 'center' }}>
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <TopNavigation title={"Organizasyon Katılım Raporu"} showNavigation={false} />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Organizasyon</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedOrganzation == null ? 0 : selectedOrganzation}
              defaultValue={''}
              label="Organizasyon"
              onChange={handleOrganizationChange}
            >
              {
                organizationList?.map((item, key) => {
                  return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>;
                })
              }
            </Select>
          </FormControl>
          <Typography>Toplam katılım: {guestList.length}</Typography>
          <Table scroll={{ x: 1 }} dataSource={guestList} columns={columns} style={{ padding: -24 }} />
        </Card>
      </Container>
    </BasePage>
  )
}

export default Attendee