import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { getGuestSessionStatus, getSessionStatus } from './auth-helper';

function PrivateRoute({ component: Component, ...rest }) {
    const location = useLocation();
    let sessionStatus = getSessionStatus();
    // console.log("sessionStatus", sessionStatus);
    return sessionStatus ? <Outlet /> : <Navigate to="/admin/login" state={{ from: { pathname: location.pathname } }} />;
}
function PrivateGuestRoute({ component: Component, ...rest }) {
    const location = useLocation();
    let sessionStatus = getGuestSessionStatus();
    return sessionStatus ? <Outlet /> : <Navigate to="/admin/login" state={{ from: { pathname: location.pathname } }} />;
}

export { PrivateRoute, PrivateGuestRoute };