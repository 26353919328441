import React, { useEffect } from 'react';
import BasePage from '../core/base-page';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, userSlice } from '../../app/reducers/user';
import { ServiceHelper } from '../../utils/service-helper';
import { getSession } from '../../utils/auth-helper';

function Profile() {
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!user) {
  //     const { email } = getSession();
  //     async function getUserInfo() {
  //       const userInfo = await ServiceHelper.post('/user/getByEmail', { email });
  //       return userInfo;
  //     }
  //     dispatch(userSlice.actions.setUserInfo(getUserInfo()))
  //   }
  // }, [])
  useEffect(() => {
    getUserInfo();
    async function getUserInfo() {
      const { id } = await getSession();
      const userInfo = await ServiceHelper.get(`/users/get/${id}`);
      const { firstName, lastName, email } = userInfo.data.result;
      dispatch(userSlice.actions.setUserInfo({ data: { id, firstName, lastName, email } }))
    }
  }, []);
  
  return (
    <BasePage>
      <Container maxWidth="sm">
        <Stack direction="row" justifyContent="center">
          <Avatar sx={{ mt: 2, width: 180, height: 180 }} // src="https://i.pravatar.cc/300"
          >
            <Typography variant="h1">{user?.firstName && `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}</Typography>
          </Avatar>
        </Stack>
        <Typography variant="h2" align="center" >
          Profile
        </Typography>
        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          {user?.firstName && `${user.firstName} ${user.lastName}`}
        </Stack>
      </Container>
    </BasePage>
  )
}

export default Profile
