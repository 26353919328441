import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BasePage from '../core/base-page';
import { Box, Card, CardContent, Container, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Typography } from '@mui/material';
import { ServiceHelper } from '../../utils/service-helper';
import { useDispatch } from 'react-redux';
import { statusSlice } from '../../app/reducers/status';
import { Switch } from 'antd';

function Tracker() {
  const { organizationId, guidId } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const [guest, setGuest] = useState({});
  const [events, setEvents] = useState([]);
  const [eventStatus, setEventStatus] = useState([]);
  const dispatch = useDispatch();
  // console.log('PARAMS: ', organizationId, guidId);

  useEffect(() => {
    getEvents();
  }, []);


  async function getEvents() {
    const response = await ServiceHelper.get(`/event/get/${organizationId}/${guidId}`);
    console.log(response);
    if (response.success) {
      const { guest, events, eventAttendance } = response.data.result;
      dispatch(statusSlice.actions.setStatus({ ...response, status: true }))
      setGuest(guest)
      setEvents(events)

      let eventStatusList = {};
      events.forEach((p) => {
        const log = eventAttendance.find(x => x.eventId == p.id);
        if (log) {

          eventStatusList = Object.assign(eventStatusList, { [p.id]: true })
        } else {
          eventStatusList = Object.assign(eventStatusList, { [p.id]: false })
        }
      });
      setEventStatus(eventStatusList)
    } else {

    }
    // dispatch(statusSlice.actions.setStatus(response))
    // console.log(response);
    // setOrganizationList(response.data.result);
  }

  const { firstName, lastName, phoneNumber, provionce } = guest;

  const getEventStatus = (event) => {
    return eventStatus[event.id];
  }

  const handleChangeEventStatus = (checked, item) => {
    setSubmitted(true);
    trackGuest({ eventId: item.id, guestId: guest.id, status: checked });
    setEventStatus({ ...eventStatus, [item.id]: checked });

  }

  const trackGuest = async (eventLog) => {
    let response = await ServiceHelper.post('/event/track', eventLog);
    // console.log('USER DATA: ', response);
    if (response.success) {
      dispatch(statusSlice.actions.setStatus({ ...response, status: true }))
      setSubmitted(false)
    } else {
      dispatch(statusSlice.actions.setStatus({ ...response, status: true }))
      setSubmitted(false)
    }
  }
  console.log('submitted ', submitted);
  return (
    <BasePage maxWidth={'md'}>
      <Container maxWidth="md" sx={{ mt: 2 }} >
        <Typography variant="h6" align="center" >
          Katılımcı Bilgileri
        </Typography>

        <Card>
          <CardContent>
            <Grid container>
              {
                guest && <>
                  <Grid item sm={6} xs={6}>
                    <Typography color={'GrayText'} variant='caption'>Adı</Typography>
                    <Typography variant='body1'>{firstName}</Typography>
                  </Grid>

                  <Grid item sm={6} xs={6}>
                    <Typography color={'GrayText'} variant='caption'>Soyadı</Typography>
                    <Typography variant='body1'>{lastName}</Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography color={'GrayText'} variant='caption'>Telefonu</Typography>
                    <Typography variant='body1'>{phoneNumber}</Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography color={'GrayText'} variant='caption'>Katıldığı İl</Typography>
                    <Typography variant='body1'>{provionce}</Typography>
                  </Grid>
                </>
                // <Typography>{lastName}</Typography>
                // <Typography>{phoneNumber}</Typography>
                // <Typography>{provionce}</Typography>
              }
            </Grid>
          </CardContent>
        </Card>
        <Typography variant="h6" align="center" sx={{ mt: 2 }}>
          Etkinlik Bilgileri
        </Typography>

        {/* <Grid container> */}
        <Card>
          <CardContent>

            {
              events?.map((item, key) => {
                return (
                  <FormControl key={key} component="fieldset" variant="standard" fullWidth
                    sx={{
                      borderRadius: 5, mb: 2, backgroundColor: getEventStatus(item) ? '#4aa527' : '#f4f4f4', p: 2,
                      color: getEventStatus(item) ? '#fff' : '#000'
                    }}>
                    <FormGroup>
                      {/* <FormLabel>{
                        new Date(item.startDate).toLocaleTimeString('tr') + " - " + new Date(item.endDate).toLocaleTimeString('tr')
                      }</FormLabel> */}
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography>{item.name}</Typography>
                        <Switch style={{ backgroundColor: getEventStatus(item) ? '#1b66b7' : 'rgb(255 0 0 / 25%)' }} checked={getEventStatus(item)} onChange={(e) => { handleChangeEventStatus(e, item) }} loading={submitted} />
                      </Box>
                      {/* <FormControlLabel labelPlacement='start'
                        control={
                          <Switch checked={getEventStatus(item)} onChange={(e) => { handleChangeEventStatus(e, item) }} loading={submitted} />
                        }
                      // label={item.name}
                      /> */}
                    </FormGroup>

                  </FormControl>

                  // <Grid item key={key} xs={12} sm={12}>
                  //   <Typography>{item.name}</Typography>
                  // </Grid>
                )
              })
            }

          </CardContent>
        </Card>
        {/* </Grid> */}
      </Container>
    </BasePage >
  )
}

export default Tracker