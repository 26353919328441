import { useEffect, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import BasePage from '../core/base-page';
import { Chip, Container, Stack } from '@mui/material';
import { Button, Card, Divider, Tabs } from 'antd';
import TopNavigation from '../../components/top-navigation';
import { ServiceHelper } from '../../utils/service-helper';
import dayjs from 'dayjs';
import { getGuestSession } from '../../utils/auth-helper';
import { useNavigate } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';


function CustomizedTimeline() {
  const [eventList, setEvents] = useState();
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState("Tümü");

  useEffect(() => {
    getAllEvents();
  }, []);

  async function getAllEvents() {
    const { organizationId } = await getGuestSession();
    const response = await ServiceHelper.get(`/event/getAll/${organizationId}`);
    setEvents(response.data.result);
    // console.log('guidId', response);
  }

  const getEventTime = (start, end) => {
    return <Stack direction={'column'}>
      <div>{dayjs(start).format('HH:mm')}</div>
      <div>{dayjs(end).format('HH:mm')}</div>
    </Stack>
  }

  const getEventGroupChip = (eventGroup) => {
    let event;
    switch (eventGroup) {
      case 1:
        event = <Chip label={"Genel"} color="primary" size="small" />
        break;
      case 2:
        event = <Chip label={"Beyler"} color="success" size="small" />
        break;
      case 3:
        event = <Chip label={"Hanımlar"} color="error" size="small" />
        break;
      default:
        break;
    }

    return event;
  }

  const getAllEventsByEventGroup = async (eventGroup) => {
    const { organizationId } = await getGuestSession();
    const response = await ServiceHelper.get(`/event/getAll/${organizationId}/${eventGroup}`);
    setEvents(response.data.result);
  }

  const filterTimeline = async (type) => {
    switch (type) {
      case "Tümü":
        setSelectedFilter("Tümü");
        getAllEvents();
        break;
      case "Genel":
        setSelectedFilter("Genel");
        getAllEventsByEventGroup(1);
        break;
      case "Beyler":
        setSelectedFilter("Beyler");
        getAllEventsByEventGroup(2);
        break;
      case "Hanımlar":
        setSelectedFilter("Hanımlar");
        getAllEventsByEventGroup(3);
        break;
      default:
        break;
    }
  }

  // this gives an object with dates as keys
  const groups = eventList?.reduce((groups, event) => {
    const date = event.startDate.split('T')[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(event);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = groups && Object.keys(groups).map((date) => {
    return {
      date,
      events: groups[date]
    };
  });

  const timeLine = (eventList) => {
    return (
      <Timeline sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}>
        {
          eventList?.map((event) =>
            <TimelineItem key={event.id}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {getEventTime(event.startDate, event.endDate)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color='info' />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ m: 'auto 0' }}
              >
                {/* <Typography variant="h6" component="span">
                {event.name}
              </Typography> */}
                <Stack direction="row" spacing={1}>
                  {getEventGroupChip(event.eventGroup)}
                  <Typography variant="subtitle1">{event.name}</Typography>
                  <Button type='link' icon={<InfoCircleOutlined />} onClick={() => {
                    navigate('/guest/event-detail', { state: { event: event } });
                  }}>
                  </Button>
                </Stack>
              </TimelineContent>
            </TimelineItem>
          )
        }
      </Timeline>
    );
  }



  return (
    <BasePage maxWidth={'md'}>
      <Container maxWidth="md" sx={{}} style={{ padding: 8, justifyContent: 'center' }}>
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <TopNavigation title={"Program Akışı"} />

          <Stack direction="row" justifyContent={'center'} spacing={1}>
            <Chip label={"Tümü"} color={selectedFilter == "Tümü" ? "warning" : "default"} size="medium" clickable onClick={() => { filterTimeline('Tümü') }} />
            <Chip label={"Genel"} color={selectedFilter == "Genel" ? "warning" : "default"} size="medium" clickable onClick={() => { filterTimeline('Genel') }} />
            <Chip label={"Beyler"} color={selectedFilter == "Beyler" ? "warning" : "default"} size="medium" clickable onClick={() => { filterTimeline('Beyler') }} />
            <Chip label={"Hanımlar"} color={selectedFilter == "Hanımlar" ? "warning" : "default"} size="medium" clickable onClick={() => { filterTimeline('Hanımlar') }} />
          </Stack>
          <Divider />
          <Tabs
            style={{ margin: 8 }}
            // onChange={onChange}
            type="card"
            items={groupArrays?.map((item, i) => {
              const id = String(i + 1);
              return {
                label: `${dayjs(item.date).locale('tr').format('dddd')}`,
                key: id,
                children: timeLine(item.events),
              };
            })}
          />


        </Card>
      </Container>
    </BasePage>

  );
}

export default CustomizedTimeline