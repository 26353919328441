import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Await, useLocation, useNavigate } from 'react-router-dom';
import Copyright from '../../components/copyright';
import { selectUser, userSlice } from '../../app/reducers/user'
import { getError, statusSlice } from '../../app/reducers/status'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { ServiceHelper } from '../../utils/service-helper';
import BasePage from '../core/base-page';
import SubmitButton from '../../components/submit-button';
import { Card, Space, Button as AntButton } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';


function Login() {
  // const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    email: '',
    password: ''
  });
  const [inputErrors, setInputErrors] = useState({
    emailError: false,
    passwordError: false
  })
  const [submitted, setSubmitted] = useState(false);
  const { email, password } = inputs;
  const { emailError, passwordError } = inputErrors;

  // reset login status
  useEffect(() => {
    // dispatch(userSlice.actions.logout());
    // localStorage.clear();
    // console.log(user);
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputValues => ({ ...inputValues, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    setInputErrors({
      emailError: false,
      passwordError: false
    })
    if (email == "") {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, emailError: true }))
    }
    if (password == "") {
      setInputErrors(inputErrorValues => ({ ...inputErrorValues, passwordError: true }))
    }

    if (email && password) {
      // get return url from location state or default to home page
      let response = await signInUser({ email, password })
      if (response.success === false) {
        console.log(response.error);
        dispatch(statusSlice.actions.setStatus({ ...response, type: 'error', status: true }))
      } else {
        const { from } = { from: { pathname: "/admin/dashboard" } };
        dispatch(userSlice.actions.login(response.data));
        dispatch(statusSlice.actions.setStatus({ message: 'Kullanıcı girişi yapıldı', type: 'success', status: true }))
        navigate(from.pathname);
      }
    }
    setSubmitted(false);
  }

  const signInUser = async (params) => {
    let response = await ServiceHelper.post('/users/signin', params)
    return response;
  }


  return (
    <BasePage maxWidth={'sm'}>
      <Container component="main" maxWidth="xs" sx={{ mt: 2 }}>
        <Card bordered={false}>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 2, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Giriş Yap
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-posta Adresi"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                error={emailError}
                helperText={emailError && 'Email is required'}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Şifre"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
                error={passwordError}
                helperText={passwordError && 'Password is required'}
              />
              {/* <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
              GİRİŞ
            </Button> */}
              <Space style={{ display: 'grid', marginTop: '16px' }}>
                <SubmitButton submitted={submitted} iconName={"Login"} variant={'contained'} fullWidth title={"GİRİŞ"} />
              </Space>
              
              <Space style={{ display: 'flex', marginTop: '16px', justifyContent: 'flex-end' }}>
                  <AntButton type="link" shape="round" size={"middle"} icon={<UserAddOutlined />} style={{ color: 'gray' }} onClick={() => {
                    navigate('/admin/register')
                  }}>Hesap Oluştur</AntButton>
              </Space>
            </Box>
          </Box>
        </Card>
      </Container>
    </BasePage>
  )
}

export default Login
