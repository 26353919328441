import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    result: {},
    message: '',
    status: false,
    type: 'success'
};


export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      const data = action.payload;
      state.result = data.error;
      state.message = data.message;
      state.status = data.status;
      state.type = data.type
    }
  }
})

// export const statusSlice = createSlice({
//   name: 'status',
//   initialState,
//   reducers: {
//     setStatus: (state, action) => {
//       const data = action.payload;
//       state.message = data.message
//       state.status = data.success
//     }
//   }
// })

// export const getError = (state) => { return {...state.error}};
export const getStatus = (state) => { return {...state.status}};

export default statusSlice.reducer;
//  {
//   errorSlice: errorSlice.reducer,
//   // statusSlice: statusSlice.reducer
// };