import { Container, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { Button, Card, Image, Space, Statistic, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { getSession } from '../../utils/auth-helper';
import { ServiceHelper } from '../../utils/service-helper';
import BasePage from '../core/base-page';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import { DownloadOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { exportToExcel } from '../../utils/excel-export';
import dayjs from 'dayjs';
import { getGenderName } from '../../utils/event-helper';


function AdminDashBoard() {
  const theme = useTheme();
  const [selectedOrganzation, setOrganization] = useState('');
  const [organizationList, setOrganizationList] = useState();
  const [attendanceCount, setAttendanceCount] = useState([]);
  const [guestCount, setGuestCount] = useState(0);
  const [eventCount, setEventCount] = useState(0);

  useEffect(() => {
    getOrganizations();
    // getAllEvents();
  }, []);

  async function getGuestCount(organizationId) {
    const response = await ServiceHelper.get(`/guest/getCount/${organizationId}`);
    setGuestCount(response.data.result);
  }


  async function getEventCount(organizationId) {
    const response = await ServiceHelper.get(`/event/getCount/${organizationId}`);
    setEventCount(response.data.result);
  }

  async function getAttendanceCount(organizationId) {
    const response = await ServiceHelper.get(`/organizationattendancelog/getAttendanceCount/${organizationId}`);
    setAttendanceCount(response.data.result?.userCount);
  }

  async function getOrganizations() {
    const { id } = await getSession();
    const response = await ServiceHelper.get(`/organization/getAll/${id}`);
    setOrganizationList(response.data.result);
  }

  const handleOrganizationChange = (event) => {
    setOrganization(event.target.value);
    getGuestCount(event.target.value);
    getEventCount(event.target.value);
    getAttendanceCount(event.target.value);
  };

  const columns = [
    {
      title: 'Excele İndir',
      key: 'download',
      render: (_, record) => (

        <Space size="middle" >
          <Button icon={<DownloadOutlined />} style={{ backgroundColor: theme.palette.primary.main, color: '#ffffff' }} onClick={async () => {
            const response = await ServiceHelper.get(`/eventattendancelog/getExcel/${record.Event?.id}`);
            let excelData = [];
            response.data.result.forEach(data => {
              excelData.push({
                'Adı': data.Guest.firstName,
                'Soyadı': data.Guest.lastName,
                'İl': data.Guest.provionce,
                'Doğum Tarihi': dayjs(data.Guest.birthDate).locale('tr').format('DD.MM.YYYY'),
                'Kayıt Tarihi': dayjs(data.createdAt).locale('tr').format('DD.MM.YYYY HH:mm')
              })
            });
            exportToExcel({ excelData: excelData, fileName: record.Event?.name+ '_Katılımcı_Listesi_' + dayjs().locale('tr').format('DD_MM_YYYY_HH_mm')})
          }}>
          </Button>
        </Space>
      ),
    },
    {
      title: 'Etkinlik Adı',
      key: 'Event.name',
      render: (_, record) => (
        <Typography>{record.Event?.name}</Typography>
      ),
    },
    {
      title: 'Balangıç Saati',
      key: 'Event.startDate',
      render: (_, record) => (
        <Typography>{dayjs(record.Event?.startDate).locale('tr').format('dddd HH:mm')}</Typography>
      ),
    },
    {
      title: 'Etkinlik Grubu',
      key: 'Event.gender',
      render: (_, record) => (
        <Typography>{getGenderName(record.Event?.eventGroup)}</Typography>
      ),
    },
    {
      title: 'Toplam Katılım',
      dataIndex: 'userCount',
      key: 'userCount',
    }
  ];

  return (
    <BasePage>
      <Container maxWidth="md">
        <Card bordered={false} style={{
          marginTop: 16,
          marginBottom: 16,
        }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Organizasyon</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedOrganzation == null ? 0 : selectedOrganzation}
              defaultValue={''}
              label="Organizasyon"
              onChange={handleOrganizationChange}
            >
              {
                organizationList?.map((item) => {
                  return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>;
                })
              }
            </Select>
          </FormControl>
        </Card>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6}>
            <Card bordered={false}>
              <Statistic
                title="Toplam Kayıtlı Kişi Sayısı"
                value={guestCount}
                precision={0}
                valueStyle={{
                  color: '#005a86',
                  fontSize: '32px'
                }}
                prefix={<PeopleIcon />}
                suffix="kişi"
              />
            </Card>
          </Grid>

          <Grid item sm={6} xs={6}>
            <Card bordered={false}>
              <Statistic
                title="Etkinlik Sayısı"
                value={eventCount}
                valueStyle={{
                  color: '#b50000',
                  fontSize: '32px'
                }}
                prefix={<EventIcon />}
                suffix="Etkinlik"
              />
            </Card>
          </Grid>

          <Grid item sm={12} xs={12}>
            <Card bordered={false}>
              <Statistic
                title="Organizasyona Katılan Kişi Sayısı"
                value={attendanceCount}
                valueStyle={{
                  color: '#089b00',
                  fontSize: '32px'
                }}
                prefix={<PeopleIcon />}
                suffix="Katılımcı"
              />
            </Card>
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <Table dataSource={eventList} columns={columns} />
          </Grid> */}
        </Grid>
      </Container>
    </BasePage>
  )
}

export default AdminDashBoard
