import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { statusSlice } from '../../app/reducers/status';
import { userSlice } from '../../app/reducers/user';
import { ServiceHelper } from '../../utils/service-helper';
import BasePage from '../core/base-page';
import { Card, Space, Button as AntButton } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

export default function SignUp() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    // console.log({
    //   firstName: data.get('firstName'),
    //   lastName: data.get('lastName'),
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
    // console.log('HAS: ', data.get('firstName'))
    let user = {
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: data.get('email'),
      password: data.get('password'),
    }
    registerUser(user);
  };

  const registerUser = async (userData) => {
    let response = await ServiceHelper.post('/users/signup', userData);
    // console.log('USER DATA: ', response);
    if (response.success) {
      // dispatch(userSlice.actions.login(response.data));
      // const { from } = location.state || { from: { pathname: "/admin/dashboard" } };
      dispatch(statusSlice.actions.setStatus({ message: response.message, type: 'warning', status: true }))
      navigate('/');
    } else {
      dispatch(statusSlice.actions.setStatus(response))
    }
  }

  return (
    <BasePage maxWidth={'sm'}>
      <Container component="main" maxWidth="xs" sx={{ mt: 2 }}>
        <CssBaseline />
        <Card bordered={false}>
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Hesap Oluştur
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="Adınız"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Soyadınız"
                    name="lastName"
                    autoComplete="lastname"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="E-Posta Adresi"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Şifre"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                KAYIT OL
              </Button>

              <Space style={{ display: 'flex', marginTop: '16px', justifyContent: 'flex-end' }}>
                <AntButton type="link" shape="round" size={"middle"} icon={<LoginOutlined />} style={{ color: 'gray' }} onClick={() => {
                  navigate('/admin/login')
                }}>Giriş Yap</AntButton>
              </Space>
            </Box>
          </Box>
        </Card>
      </Container>
    </BasePage>
  );
}