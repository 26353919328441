async function get(url, params) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') }
  };
  return fetch(getApiUrl(url) + "?" + new URLSearchParams(params), requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

function getWithoutToken(url) {
  const requestOptions = {
    method: 'GET'
  };
  return fetch(getApiUrl(url), requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

async function post(url, body) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
    body: JSON.stringify(body)
  };
  return await fetch(getApiUrl(url), requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

function postWithImage(url, body) {
  const requestOptions = {
    method: 'POST',
    headers: {
      // 'Content-Type': `multipart/form-data; boundary=place`
      // 'Content-Type': 'multipart/form-data', 'x-access-token': localStorage.getItem('token') 
    },
    body: body
  };
  return fetch(getApiUrl(url), requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

function put(url, body) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
    body: JSON.stringify(body)
  };
  return fetch(getApiUrl(url), requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

function putWithImage(url, body) {
  const requestOptions = {
    method: 'PUT',
    // headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
    body: body
  };
  return fetch(getApiUrl(url), requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
    // body: body
  };
  return fetch(getApiUrl(url), requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

// helper functions

async function handleResponse(response) {
  if (!response.ok) {
    const { status, statusText, url } = response;
    let apiResult = await response.text();
    let jsonResult;
    try {
      jsonResult = JSON.parse(apiResult);
    } catch (e) {
      jsonResult = { message: apiResult } // error in the above string (in this case, yes)!
    }
    return Promise.resolve(handleError(jsonResult, { success: false, type: 'error', result: { status, statusText, url } }));

  }
  else {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      return data;
    });

  }
}

function handleError(apiResult, response) {

  return { ...response, message: apiResult.message };
}

function getApiUrl(url) {
  const mainURL = `${getMainApiUrl()}/api`; //  process.env.NODE_ENV == 'production' ? "https://yad.mec.org.tr/api" : "http://localhost:8001/api";
  return mainURL + url;
}

function getUrl(url) {
  const mainURL = `${getMainUrl()}/`;
  // const mainURL = process.env.NODE_ENV == 'production' ? "https://yad.mec.org.tr/" : "http://localhost:3000/";
  return mainURL + url;
}

function getImageUrl(url) {
  // const mainURL = process.env.NODE_ENV == 'production' ? "https://yad.mec.org.tr/" : "http://localhost:8001/";
  const mainURL = `${getMainApiUrl()}/`;
  return mainURL + url;
}

const getMainApiUrl = () => {
  // let url = "http://localhost:8001";
  let url = "https://lcv.server.global";
  // switch (process.env.REACT_APP_NODE_ENV) {
  //   case "production":
  //     url = "https://lcv.server.global"
  //     break;
  //   case "test":
  //     url = "https://server.mustafayonca.com"
  //     break;
  //   default:
  //     break;
  // }
  return url;
}

const getMainUrl = () => {
  // let url = "http://localhost:3000";
  let url = "https://lcv.server.global";
  // switch (process.env.REACT_APP_NODE_ENV) {
  //   case "production":
  //     url = "https://lcv.server.global"
  //     break;
  //   case "test":
  //     url = "https://server.mustafayonca.com"
  //     break;
  //   default:
  //     break;
  // }
  return url;
}

export const ServiceHelper = {
  get,
  getWithoutToken,
  post,
  postWithImage,
  put,
  putWithImage,
  delete: _delete,
  getUrl: getUrl,
  getImageUrl: getImageUrl
};
